import { Actions, ofType } from "@ngrx/effects";
import { AppConfigService } from "../../../wall/services/app-config.service"
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ExecutiveDashboardActionsTypes,
  FetchExecutiveDashboard,
  UpdateExecutiveDashboardConfig,
  UpdateExecutiveDashboardConfigFailure,
  UpdateExecutiveDashboardConfigSuccess,
} from "../../../wall/actions/executive-dashboard.actions";
import {
  ExecutiveDashboardConfiguration,
  SingleJobStageThreshold,
} from "../../../wall/models/executive-dashboard";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { cloneDeep, pick } from "lodash-es";
import {
  fillMissingKeys,
  objKeysSafe,
} from "../../../shared/utils/general-utils";
import { first } from "rxjs/operators";
import {
  selectAllJobStageNames,
  selectExecutiveDashboardConfig,
} from "../../../wall/reducers";
import {
  selectImmediately,
  selectImmediatelySync,
} from "../../../shared/utils/store.utils";

@Component({
  selector: "twng-edit-executive-dashboard-thresholds-modal",
  templateUrl: "./edit-executive-dashboard-thresholds-modal.component.html",
  styleUrls: [
    "./edit-executive-dashboard-thresholds-modal.component.scss",
    "../../../shared/components/grid.scss",
  ],
})
export class EditExecutiveDashboardThresholdsModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  private sub = new Subscription();
  private formInitialized = false;
  isDemo = window.twng_demo;
  jobStages: string[];
  get jobStageThresholdsGroup() {
    return this.form.controls.job_stage_thresholds as UntypedFormGroup;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private actions: Actions,
    private toastr: ToastrService,
    public app: AppConfigService,
  ) {}

  ngOnInit() {
    this.jobStages = selectImmediatelySync(this.store, selectAllJobStageNames);
    this.initializeForm();
    this.sub.add(
      this.form.valueChanges.subscribe(() => {
        // without animation frame we get expression changed after view is checked
        requestAnimationFrame(() => {
          this.refreshFormDisabledStuff();
        });
      })
    );
  }

  // called when table component is available
  private async initializeFormValue() {
    if (this.formInitialized) {
      return;
    }
    this.formInitialized = true;
    const currentConfig = await selectImmediately(
      this.store,
      selectExecutiveDashboardConfig
    );

    const valueToSet = pick(
      cloneDeep(currentConfig),
      objKeysSafe(this.form.controls)
    );

    this.initializeJobStagesForm(valueToSet, currentConfig);
    this.form.setValue(valueToSet);
  }

  private initializeJobStagesForm(
    valueToSet: Partial<ExecutiveDashboardConfiguration>,
    currentConfig: ExecutiveDashboardConfiguration
  ) {
    // remove any extra stage values
    valueToSet.job_stage_thresholds = pick(
      currentConfig.job_stage_thresholds,
      this.jobStages
    );
    // add any missing stage values
    const jobStagefillValue: SingleJobStageThreshold = {
      red: null,
      yellow: null,
    };
    fillMissingKeys(
      valueToSet.job_stage_thresholds,
      this.jobStages,
      jobStagefillValue
    );
  }

  initializeForm() {
    this.form = new UntypedFormGroup({
      projected_hires_use_green_instead_of_gray: new UntypedFormControl(),
      projected_hires_yellow_threshold: new UntypedFormControl(undefined, Validators.min(0.1)),
      projected_hires_red_threshold: new UntypedFormControl(undefined, Validators.min(0.1)),
      days_open_use_green_instead_of_gray: new UntypedFormControl(),
      days_open_yellow_threshold: new UntypedFormControl(undefined, Validators.min(1)),
      days_open_red_threshold: new UntypedFormControl(undefined, Validators.min(1)),
      days_open_exclude_jobs_with_more_openings: new UntypedFormControl(),
      conversion_rates_use_green_instead_of_gray: new UntypedFormControl(),
      conversion_rates_yellow_threshold: new UntypedFormControl(undefined, [Validators.min(0.1), Validators.max(99.9)]),
      conversion_rates_red_threshold: new UntypedFormControl(undefined, [Validators.min(0.1), Validators.max(99.9)]),
      target_hire_days_after_opening: new UntypedFormControl(),
      target_hire_date_use_green_instead_of_gray: new UntypedFormControl(),
      target_hire_date_yellow_threshold: new UntypedFormControl(undefined, Validators.min(1)),
      target_hire_date_red_threshold: new UntypedFormControl(undefined, Validators.min(1)),
      use_individual_job_stage_thresholds: new UntypedFormControl(),
      job_stage_thresholds: new UntypedFormGroup(
        Object.fromEntries(
          this.jobStages.map((jobStage) => [
            jobStage,
            new UntypedFormGroup({
              red: new UntypedFormControl(),
              yellow: new UntypedFormControl(),
            }),
          ])
        )
      ),
    });

    this.initializeFormValue()
  }

  jobStageControl(stage: string) {
    return this.jobStageThresholdsGroup.controls[stage] as UntypedFormGroup;
  }

  close() {
    this.activeModal.close();
  }

  save() {
    this.form.disable();
    const currentFormValue: ExecutiveDashboardConfiguration = this.form.value;

    this.store.dispatch(new UpdateExecutiveDashboardConfig(currentFormValue));
    this.actions
      .pipe(
        ofType(
          ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigFailure,
          ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigSuccess
        ),
        first()
      )
      .subscribe(
        (
          action:
          | UpdateExecutiveDashboardConfigSuccess
          | UpdateExecutiveDashboardConfigFailure
        ) => {
          if (
            action.type ==
            ExecutiveDashboardActionsTypes.UpdateExecutiveDashboardConfigSuccess
          ) {
            this.savedSuccessfully();
          } else {
            this.form.enable();
            this.toastr.error("Error saving configuration");
          }
        }
      );
  }

  private savedSuccessfully() {
    this.store.dispatch(new FetchExecutiveDashboard());
    this.actions
      .pipe(
        ofType(ExecutiveDashboardActionsTypes.FetchExecutiveDashboardSuccess),
        first()
      )
      .subscribe(() => {
        this.close();
      });
  }

  // frontend validation to check relation between redThreshold and yellowThreshold.
  // Adds error if yellowThreshold >= redThreshold AND in non-inverted operation.
  // Adds error if yellowThreshold <= redThreshold AND inverted operation.
  // Inverted operation is used to invert checks in all kinds of different
  private checkFormThresholdValues(
    yellowThresholdName: string,
    redThresholdName: string,
    errorName: string,
    accumulatedErrors: { [errorKey: string]: boolean },
    inverted = false,
    group: UntypedFormGroup = this.form
  ) {
    if (
      group.value[yellowThresholdName] !== null &&
      group.value[redThresholdName] !== null
    ) {
      if (
        (!inverted &&
          group.value[yellowThresholdName] >= group.value[redThresholdName]) ||
        (inverted &&
          group.value[yellowThresholdName] <= group.value[redThresholdName])
      )  {
        if (!group.hasError(errorName)) {
          accumulatedErrors[errorName] = true;
        }
      }
    }
  }

  private refreshFormDisabledStuff() {
    if (this.form.disabled) {
      return;
    }
    const accumulator: { [errorKey: string]: boolean } = {};
    this.checkFormThresholdValues(
      "days_open_yellow_threshold",
      "days_open_red_threshold",
      "daysOpenYellowAboveRed",
      accumulator
    );
    this.checkFormThresholdValues(
      "target_hire_date_yellow_threshold",
      "target_hire_date_red_threshold",
      "targetHireDateYellowBelowRed",
      accumulator,
      true
    );
    this.checkFormThresholdValues(
      "conversion_rates_yellow_threshold",
      "conversion_rates_red_threshold",
      "conversionRatesYellowBelowRed",
      accumulator,
      true
    );
    this.checkFormThresholdValues(
      "projected_hires_yellow_threshold",
      "projected_hires_red_threshold",
      "projectedHiresYellowBelowRed",
      accumulator,
      true
    );
    this.jobStages.forEach((jobStage) => {
      this.checkFormThresholdValues(
        "yellow",
        "red",
        jobStage + "YellowBelowRed",
        accumulator,
        true,
        this.jobStageThresholdsGroup.controls[jobStage] as UntypedFormGroup
      );
    });
    this.form.setErrors(objKeysSafe(accumulator).length > 0 ? accumulator : null)
  }
}
