import { Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import { CandidateTag } from '../models/candidate-tag'
import { FilterOption } from '../../shared/components/filters/generic-filter/filter-option.interface'
import { objKeysSafe } from '../../shared/utils/general-utils'

@Component({
  selector: 'twng-candidate-tag-filters',
  styles: [
    `
      :host {
        flex-grow: 1;
        width: 100%;
      }
    `,
  ],
  template: `
    <twng-generic-filter [options]="tags"
                         placeholder="Filter by tag"
                         (selectedOptionsChange)="selectedOptionsChange($event)"
                         [defaultSelectedOptions]="defaultSelectedTags">
    </twng-generic-filter>
  `,
})
export class CandidateTagFiltersComponent implements OnInit, OnDestroy {

  @Output()
    filtersChange = new EventEmitter<{ tags: string[] }>()

  @Input()
    initialTags: { [key: string]: boolean }

  defaultSelectedTags: FilterOption[] = []

  tags: CandidateTag[]
  tagsSub: Subscription

  constructor(
    private store: Store<fromWall.State>
  ) {
  }

  ngOnInit(): void {
    this.setInitialTags()

    this.tagsSub = this.store.select(fromWall.selectAllCandidateTags)
      .subscribe(tags => {
        this.tags = tags.map(x => ({ id: x.name, name: x.name }))
      })
  }

  ngOnDestroy(): void {
    this.tagsSub.unsubscribe()
  }

  setInitialTags() {
    this.defaultSelectedTags = objKeysSafe(this.initialTags)
      .filter(tagName => this.initialTags[tagName])
      .map(tagName => ({
        id: tagName,
        name: tagName
      }))
  }

  selectedOptionsChange(selectedOptions: FilterOption[]) {
    const tagsNames: string[] = selectedOptions.map(filterOption => filterOption.name)
    this.filtersChange.emit({
      tags: tagsNames
    })
  }
}
