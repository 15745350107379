<ng-container *ngIf="isTabLoadingFinished$ | async">
  <ul ngbNav class="nav nav-tabs app__tabs sub-tabs" [activeId]="(route.firstChild.params | async).tabId">
    <li ngbNavItem="open_jobs">
      <a ngbNavLink routerLink="open_jobs" class="fs-unmask">
        Open Jobs
      </a>
    </li>
    <li *ngFor="let tab of nonDefaultTabs()" [ngbNavItem]="tab.id.toString()">
      <a ngbNavLink routerLink="{{tab.id}}" class="fs-unmask">
        {{tab.name}}
      </a>
    </li>
    <li class="nav-item">
      <a (click)="openCreateNewTabModal()" ngbTooltip="Add Tab" class="fs-unmask nav-link">
        <i class="fas fa-plus"></i>
      </a>
    </li>
    <li class="nav-item">
      <a (click)="openReorderTabsModal()" ngbTooltip="Reorder tabs" class="fs-unmask nav-link">
        <i class="fas fa-random"></i>
      </a>
    </li>
    <li class="nav-item">
      <a (click)="openConfigureDashModal()" ngbTooltip="Configure Executive Dashboard and Thresholds"
        class="fs-unmask nav-link" *ngIf="isAdmin">
        <i class="fas fa-cog icon--reorder"></i>
      </a>
    </li>
    <li ngbNavItem="closed_jobs">
      <a (click)="track('View Executive Dashboard: Closed Jobs')" ngbNavLink routerLink="closed_jobs" class="fs-unmask">
        Closed Jobs
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</ng-container>