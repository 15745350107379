<span *ngIf="tab.job_ids" class="badge badge-wire fs-unmask">Custom</span>
<twng-badge-group *ngIf="officeEntities$ | async as entities" [badgeLabels]="badgeNames(entities, tab.office_ids)">
</twng-badge-group>

<twng-badge-group *ngIf="departmentEntities$ | async as entities"
  [badgeLabels]="badgeNames(entities, tab.department_ids)"></twng-badge-group>

<twng-badge-group *ngIf="externalUserEntities$ | async as entities"
  [badgeLabels]="badgeNames(entities, tab.external_user_ids)"></twng-badge-group>

<twng-badge-group *ngIf="tab.custom_fields?.length"
  [badgeLabels]="customFieldsService.mapSelectedFilterOptionsToStrings(tab.custom_fields) | async">
</twng-badge-group>

<twng-badge-group [badgeLabels]="tab.job_priorities"></twng-badge-group>

<twng-badge-group [badgeLabels]="tab.employment_types"></twng-badge-group>