import * as fromWall from '../reducers'
import { API_OPEN_JOB_NAMES, WallApiService } from '../services/wall-api.service'
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core'
import { Department } from '../models/department'
import { FilterOption } from '../../shared/components/filters/generic-filter/filter-option.interface'
import { Observable, Subscription, combineLatest } from 'rxjs'
import { Office } from '../models/office'
import { SingleJobNamePayload } from '../../core/actions/loader.actions'
import { Store } from '@ngrx/store'
import { isApiLoaded } from '../../shared/state/selectors'
import jobNameString from '../../shared/utils/job-utils'


@Component({
  selector: 'twng-jobs-selector',
  template: `
    <twng-generic-filter [options]="allJobsForFilter"
                         [defaultSelectedOptions]="defaultSelectedJobs"
                         (selectedOptionsChange)="selectedOptionsChange($event)"
                         *ngIf="jobsLoaded$ | async">
    </twng-generic-filter>
  `,
  styles: [],
})
export class JobsSelectorComponent implements OnInit, OnDestroy {
  @Output()
    jobsChange = new EventEmitter()

  @Input()
    jobIds: string[]

  jobsLoaded$: Observable<boolean>

  jobs: SingleJobNamePayload[]
  allJobsForFilter: FilterOption[] = []
  jobFullNameSub: Subscription
  defaultSelectedJobs: FilterOption[] = []

  constructor(private store: Store<fromWall.State>, private wallApi: WallApiService, private cd: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.jobFullNameSub = combineLatest([
      this.store.select(fromWall.getOpenJobNamesFromWall),
      this.store.select(fromWall.selectDepartmentEntities),
      this.store.select(fromWall.selectOfficeEntities)]).subscribe(([jobs, departments, offices]) => {
      this.jobs = jobs
      let jobDepartments: Department[]
      let jobOffices: Office[]
      for (const job of jobs) {
        jobDepartments = job.department_ids.map(
          x => departments[x],
        )

        jobOffices = job.office_ids.map(
          x => offices[x],
        )

        const nameOfTheJob = jobNameString(job, jobDepartments, jobOffices)

        this.allJobsForFilter.push({ name: nameOfTheJob, id: job.id })
        this.allJobsForFilter.sort((a, b) => a.name.localeCompare(b.name))
      }

      // Preselected jobs to fill in the multi select
      this.defaultSelectedJobs = this.allJobsForFilter.filter(
        job => this.jobIds.includes(job.id as string)
      )
      this.cd.markForCheck()
    })

    this.store.dispatch(this.wallApi.getFetchOpenJobNamesAction())
    this.jobsLoaded$ = isApiLoaded(this.store, API_OPEN_JOB_NAMES)
  }

  ngOnDestroy(): void {
    this.jobFullNameSub.unsubscribe()
  }

  selectedOptionsChange(selectedFilterOptions: FilterOption[]) {
    const jobIds = selectedFilterOptions.map(option => option.id)
    this.jobsChange.emit(jobIds)
  }
}
