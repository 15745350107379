import { Subscription } from 'rxjs'

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit
} from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'

import { Account } from '../../wall/models/account'
import { AppConfigService } from '../../wall/services/app-config.service'
import { SignOut } from '../actions/loader.actions'
import { State, selectAccount, selectAppConfig, selectUser } from '../../reducers'
import { User } from '../../wall/models/user'
import { onTwngRoute } from '../rails-utils'
import { outerRedirect } from '../rails-integration'
import { present } from '../../shared/utils/general-utils'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User
  userSub: Subscription
  account: Account
  accountSub: Subscription
  routerSub: Subscription

  canViewDashboard: boolean
  canViewExecDashboard: boolean

  canViewWall: boolean

  appConfigSub: Subscription

  demoMode = !!window.twng_demo

  constructor(
    private store: Store<State>,
    private router: Router,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService
  ) { }

  ngOnInit() {
    this.appConfigSub = this.store.select(selectAppConfig).subscribe(appConfig => {
      // TODO: These should really be observables so that they will update on their own
      // but as it is, they are set at the same time as the user
      this.canViewDashboard = appConfig?.feature_flags?.dashboard
      this.canViewWall = appConfig?.feature_flags?.wall
      this.canViewExecDashboard =
        appConfig?.feature_flags?.hiring_management && appConfig?.feature_flags?.can_view_exec_dashboard

      this.cd.markForCheck()
    })

    this.accountSub = this.store.select(selectAccount).subscribe(account => {
      this.account = account
      this.cd.markForCheck()
    })
    this.userSub = this.store.select(selectUser).subscribe(user => {
      this.user = user
      this.cd.markForCheck()
    })
    this.routerSub = this.router.events.subscribe(() => {
      this.cd.markForCheck()
    })
  }

  ngOnDestroy(): void {
    if (this.accountSub) {
      this.accountSub.unsubscribe()
    }
    if (this.userSub) {
      this.userSub.unsubscribe()
    }
    this.routerSub.unsubscribe()
    this.appConfigSub.unsubscribe()
  }

  userInitials(user: User) {
    let wordParts: string[] =
      user.name ? user.name.split(' ')
        : user.email.split('@')[0].split('.')

    // strip non-word characters
    wordParts = wordParts.map(part => part.replace(/\W/g, '')).filter(part => present(part))

    // maximum of 3 initials; if more, just use first and last
    if (wordParts.length > 3) {
      wordParts = [wordParts[0], wordParts[wordParts.length - 1]]
    }
    const initials = wordParts.map(part => part.substr(0, 1).toUpperCase())
    return initials.join('')
  }

  goToStageMappings() {
    outerRedirect("account/interview-phases")
  }
  goToAccount() {
    outerRedirect("account/users")
  }
  goToSettings() {
    outerRedirect("settings/my_settings")
  }
  goToSignIn() {
    outerRedirect("users/sign_in")
  }
  getHelpCenterUrl() {
    return this.appConfig.helpCenterUrl()
  }
  signOut() {
    this.store.dispatch(new SignOut())
  }

  hardLink(url: string) {
    // Until the whole frontend has been ported to Angular,
    // We have to ensure we do a clean page load if we're coming from a non-angular page.
    if (!this.onTwngRoute()) {
      window.location.href = url
    }

    if (this.user.enable_allegro_exec_dash && url === '/executive-tools') {
      this.router.navigate(['/']).then(() => {
        this.router.navigate([url]);
      })
    }
  }

  onTwngRoute(): boolean {
    return onTwngRoute()
  }

  // make router link do nothing on non-twng routes, since loading will be handled by
  // hardLink() and we don't want to load twice
  routerLink(url: string) {
    if (this.onTwngRoute()) {
      return url
    }
    return null
  }

  // avoid showing links as active when routerLink returns null by not setting an active class in that case
  activeIfTwngRoute(): string {
    return this.onTwngRoute() ? "active" : ""
  }

  showInterviewPhases(): boolean {
    return this.user.admin && this.appConfig.showInterviewPhases()
  }
}
