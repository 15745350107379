import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-modal-sub-title',
  templateUrl: './modal-sub-title.component.html',
  styleUrls: ['./modal-sub-title.component.scss']
})
export class ModalSubTitleComponent {
}
