import { Observable, Subscription } from 'rxjs'
import { includes as _includes } from 'lodash-es'

import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit
} from '@angular/core'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../reducers'
import { Account } from '../models/account'
import { ActivityLevels, PossibleActivityLevels, getTooltipTextForActivity } from '../models/activity-day-categories'
import { AppConfigService } from '../services/app-config.service'
import { CandidateFilters } from '../reducers/layout.reducer'
import { FilterOption } from '../../shared/components/filters/generic-filter/filter-option.interface'
import { RatingNumber } from '../models/rating-number'
import { SegmentService } from '../../core/services/segment.service'
import { SelectedCustomFieldsFilter } from '../../custom-fields/models/selected-custom-fields-filter'
import { TagMapping } from '../models/tag-mapping'
import { UpdateCandidateFilters } from '../actions/layout.actions'
import { User } from '../models/user'
import { objKeysSafe } from '../../shared/utils/general-utils'

@Component({
  selector: 'twng-candidate-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./candidate-filters.component.scss'],
  templateUrl: './candidate-filters.component.html'
})
export class CandidateFiltersComponent implements OnInit, OnDestroy {
  @Input()
    account: Account
  @Input()
    user: User

  ratingNumbers: RatingNumber[] = [1, 2, 3, 4, 5]

  currentCandidateNameQuery = ''
  updatedQuery = new EventEmitter<string>()

  constructor(
    public store: Store<fromWall.State>,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
  ) { }

  tagMappings$: Observable<TagMapping[]>
  visibleSourceIcons$: Observable<string[]>
  activityLevels: PossibleActivityLevels[]
  filters: CandidateFilters
  sourceTypeIconMappings$: Observable<{ [key: string]: string }>
  filterSub: Subscription
  getTooltipTextForActivity = getTooltipTextForActivity

  ngOnInit(): void {
    this.tagMappings$ = this.store.pipe(select(fromWall.selectAllTagMappings))
    this.activityLevels = ActivityLevels
    this.filterSub = this.store
      .pipe(select(fromWall.selectCandidateFilters))
      .subscribe(candidateFilters => {
        this.filters = candidateFilters
        this.updatedQuery.emit(candidateFilters.matchingCandidateName)
        this.currentCandidateNameQuery = candidateFilters.matchingCandidateName
      })

    this.sourceTypeIconMappings$ = this.store.pipe(
      select(fromWall.selectSourceTypeIconMappings),
    )
  }
  ngOnDestroy(): void {
    this.filterSub.unsubscribe()
  }

  updateCustomFieldFilter(evt: SelectedCustomFieldsFilter[]) {
    this.store.dispatch(
      new UpdateCandidateFilters({
        candidateCustomFields: evt
      })
    )
  }

  updateAnyTagFilter($event): void {
    const selectedTags = $event.tags

    const selectedTagsMap = {}
    selectedTags.forEach((tag: string) => {
      selectedTagsMap[tag] = true
    })

    this.segmentService.track("Filter by Any Tag")

    this.store.dispatch(
      new UpdateCandidateFilters({
        matchingTagFilters: selectedTagsMap,
      }),
    )
  }

  toggleConfidentialJobs(): void {
    this.segmentService.track("Show Confidential Jobs (toggle)", { on: !this.filters.showConfidentialJobs })
    this.store.dispatch(
      new UpdateCandidateFilters({
        showConfidentialJobs: !this.filters.showConfidentialJobs,
      }),
    )
  }
  togglePrivateCandidates(): void {
    this.segmentService.track("Show Private Candidates (toggle)", { on: !this.filters.showPrivateCandidates })
    this.store.dispatch(
      new UpdateCandidateFilters({
        showPrivateCandidates: !this.filters.showPrivateCandidates,
      }),
    )
  }
  toggleStarred(): void {
    this.segmentService.track("Filter by Starred (toggle)", { on: !this.filters.starredOnly })
    this.store.dispatch(
      new UpdateCandidateFilters({
        starredOnly: !this.filters.starredOnly,
      }),
    )
  }

  toggleMyCandidatesOnly() {
    this.segmentService.track("Filter by My Candidates (toggle)", { on: !this.filters.myCandidatesOnly })
    this.store.dispatch(
      new UpdateCandidateFilters({
        myCandidatesOnly: !this.filters.myCandidatesOnly,
      }),
    )
  }

  toggleTagFilter(tagMapping: TagMapping): void {
    this.segmentService.track("Filter by Tag (toggle)", { on: !this.filters.matchingTagFilters[tagMapping.tag] })
    this.store.dispatch(
      new UpdateCandidateFilters({
        matchingTagFilters: {
          ...this.filters.matchingTagFilters,
          [tagMapping.tag]: !this.filters.matchingTagFilters[tagMapping.tag],
        },
      }),
    )
  }
  toggleActivityFilter(activityLevel: string) {
    let newActivityLevel: string[]

    if (this.filters.matchingActivityLevel.includes(activityLevel)) {
      newActivityLevel = this.filters.matchingActivityLevel.filter(actLvl => activityLevel !== actLvl)
    } else {
      newActivityLevel = [...this.filters.matchingActivityLevel, activityLevel]
    }

    this.segmentService.track("Filter by Activity Level", { newActivityLevel })

    this.store.dispatch(
      new UpdateCandidateFilters({
        matchingActivityLevel: newActivityLevel,
      }),
    )
  }

  setSourceFilter(source: string): void {
    this.segmentService.track("Filter by Source", { source })

    const matchingSourceFilter =
      this.filters.matchingSourceFilter.includes(source) ?
        this.filters.matchingSourceFilter.filter(s => s !== source) :
        [...this.filters.matchingSourceFilter, source]

    this.store.dispatch(
      new UpdateCandidateFilters({
        ...this.filters,
        matchingSourceFilter
      }),
    )
  }

  updateCandidateSearch(value): void {
    this.store.dispatch(
      new UpdateCandidateFilters({
        ...this.filters,
        matchingCandidateName: value,
      }),
    )
  }

  isSpecialFilterEnabled(filter: string): boolean {
    return _includes(this.appConfig.specialFilters(), filter)
  }

  isEmpty(object: Record<string, unknown>) {
    return objKeysSafe(object).length === 0
  }

  toggleInterviewCompleted() {
    this.store.dispatch(
      new UpdateCandidateFilters({
        showInterviewCompleted: !this.filters.showInterviewCompleted,
      }),
    )
  }

  toggleInterviewScheduled() {
    this.store.dispatch(
      new UpdateCandidateFilters({
        showInterviewScheduled: !this.filters.showInterviewScheduled,
      }),
    )
  }

  toggleNoInterview() {
    this.store.dispatch(
      new UpdateCandidateFilters({
        showNoInterview: !this.filters.showNoInterview,
      })
    )
  }

  recruitersFilterChanged(recruiters: FilterOption[]) {
    this.store.dispatch(
      new UpdateCandidateFilters({
        recruiters: recruiters.map(r => r.id as string),
      }),
    )
  }

  creditedToFilterChanged(filterOptions: FilterOption[]) {
    this.store.dispatch(
      new UpdateCandidateFilters({
        creditedTo: filterOptions.map(r => r.id as string),
      }),
    )
  }
}
