import { Component, Input, OnInit } from '@angular/core'
import { CustomFieldTypeNames } from '../../../custom-fields/models/custom-field'
import { CustomFieldsService, } from '../../../custom-fields/services/custom-fields.service'

@Component({
  selector: 'twng-imported-custom-fields-tooltip',
  templateUrl: './imported-custom-fields-tooltip.component.html',
  styleUrls: ['./imported-custom-fields-tooltip.component.css']
})
export class ImportedCustomFieldsTooltipComponent implements OnInit {
  sentence: string

  @Input()
    prefix: string

  constructor(public customFields: CustomFieldsService) { }

  ngOnInit(): void {
    this.sentence = `Custom fields will be visible here if they are ${Object.values(CustomFieldTypeNames).join(', ')} and non-private.`
  }
}
