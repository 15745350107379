import { BehaviorSubject, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { CacheService } from '../../../../wall/services/cache.service'
import { ExternalUser } from '../../../../wall/models/external-user'
import { FilterOption } from '../generic-filter/filter-option.interface'

@Component({
  selector: 'twng-credited-to-filter',
  templateUrl: './credited-to-filter.component.html',
  styleUrls: ['./credited-to-filter.component.scss'],
})
export class CreditedToFilterComponent implements OnInit {

  selectedCreditedTo$: BehaviorSubject<ExternalUser[]> = new BehaviorSubject([])
  creditedTo$: Observable<ExternalUser[]>

  @Input()
  // Selected CreditedTos ID in the store
    selectedCreditedTosIds: string[]

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  constructor(private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.creditedTo$ = this.cacheService.creditedToUsers$.pipe(
      tap(recruiters => this.loadSelectedCreditedTo(recruiters, this.selectedCreditedTosIds)),
      switchMap((allCreditedTo: ExternalUser[]) => this.selectedCreditedTo$.pipe(
        map((selectedCreditedTo: ExternalUser[]) =>
          allCreditedTo.filter(creditedTo => !selectedCreditedTo.find(scto => scto.id === creditedTo.id))
        )
      ))
    )
  }

  /**
   * When the component is created, preselect the creditedTo already selected from other tabs.
   *
   * @param externalUsers
   * @param creditedToIds
   */
  loadSelectedCreditedTo(externalUsers: ExternalUser[], creditedToIds: string[]): void {
    const creditedTo = externalUsers.filter(cto => creditedToIds.includes(cto.id))
    this.selectedCreditedTo$.next(creditedTo)
  }

  creditedToSelected(creditedTo: FilterOption) {
    const selectedCreditedTo = this.selectedCreditedTo$.value
    this.selectedCreditedTo$.next([...selectedCreditedTo, creditedTo as ExternalUser])
    this.emitFilterValue()
  }

  removeCreditedTo(creditedTo: FilterOption) {
    const selectedCreditedTo = this.selectedCreditedTo$.value.filter((r: ExternalUser) => r.id !== creditedTo.id)
    this.selectedCreditedTo$.next(selectedCreditedTo)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedCreditedTo$.value))
  }

  reset() {
    this.selectedCreditedTo$ = new BehaviorSubject([])
  }
}
