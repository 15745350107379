<div #widget class="widget" *ngIf="data">
  <div class="header">
    <twng-chart-label [label]="label" 
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>
  </div>

  <div>
    <div class="progress-header">
       <span>STATUS</span>
       <span>TOTAL</span>
    </div>
    <ng-container *ngFor="let item of data">
      <div class="progress-status" (click)="sliceClicked(item)" *ngIf="item.value">
        <span>{{ item.name }}</span>
        <span>{{ getPercentage(item.value) }}</span>
        <div class="progress-bar" [ngStyle]="{ 'width': getPercentage(item.value), 'background-color': color(item) }"></div>
      </div>
    </ng-container>
  </div>
</div>
