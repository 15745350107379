<twng-modal *ngIf="form">
  <twng-modal-header>
    <twng-modal-title slot="header-title">Configure Executive Dashboard and Thresholds</twng-modal-title>
    <twng-modal-sub-title slot="header-subtitle">These are account-wide settings.</twng-modal-sub-title>
  </twng-modal-header>
  <twng-modal-body>
    <form [formGroup]="form" (ngSubmit)="save()">
      <!-- Projected hires coloring -->
      <twng-edit-column-color title="Projected Hires coloring"
        noValueWillBeColoredText='No "Projected Hires" columns will be colored'
        yellowValueText="Projected Hires field becomes yellow when below this number"
        redValueText="Projected Hires field becomes red when below this number"
        useGreenInsteadOfGrayControlName="projected_hires_use_green_instead_of_gray"
        yellowThresholdControlName="projected_hires_yellow_threshold"
        redThresholdControlName="projected_hires_red_threshold" [form]="form" [isInverted]="true"
        [minPlaceholderValue]="0.9" [maxPlaceholderValue]="1.7" errorName="projectedHiresYellowBelowRed">
      </twng-edit-column-color>

      <!-- Days open coloring -->
      <hr />
      <twng-edit-column-color title="Days Open coloring"
        noValueWillBeColoredText='No "Days Open" columns will be colored'
        yellowValueText="Days Open field becomes yellow after this many days"
        redValueText="Days Open field becomes red after this many days"
        useGreenInsteadOfGrayControlName="days_open_use_green_instead_of_gray"
        yellowThresholdControlName="days_open_yellow_threshold" redThresholdControlName="days_open_red_threshold"
        errorName="daysOpenYellowAboveRed" [form]="form">
      </twng-edit-column-color>
      <div class="form-group">
        <input type="checkbox" id="days_open_exclude_jobs_with_more_openings" formControlName="days_open_exclude_jobs_with_more_openings">
        <label for="days_open_exclude_jobs_with_more_openings">
          Exclude jobs with more than one opening (evergreen roles)
        </label>
      </div>

      <!-- Target hiring days coloring -->
      <hr />
      <twng-edit-column-color title="Target hire days for all jobs"
        noValueWillBeColoredText='No "Target Hire Date" columns will be colored'
        yellowValueText="Target Hire Date field becomes yellow when days away less than this many days"
        redValueText="Target Hire Date field becomes red when days away less than this many days"
        useGreenInsteadOfGrayControlName="target_hire_date_use_green_instead_of_gray"
        yellowThresholdControlName="target_hire_date_yellow_threshold"
        redThresholdControlName="target_hire_date_red_threshold" errorName="targetHireDateYellowBelowRed" [form]="form"
        [isInverted]="true">
      </twng-edit-column-color>
      <div class="form-group">
        <label for="target-hire-days-number">
          Target days to hire, after open date.
        </label>
        <input type="number" class="tw-mt-1 form-control" placeholder="Ex. 14" id="target-hire-days-number"
          formControlName="target_hire_days_after_opening" min="0">
      </div>

      <!-- Job stages coloring -->
      <hr />
      <twng-edit-column-color title="Job Stages coloring"
        noValueWillBeColoredText='No "Job Stage" columns will be colored'
        yellowValueText="Below this value, percentage becomes yellow"
        redValueText="Below this value, percentage becomes red"
        useGreenInsteadOfGrayControlName="conversion_rates_use_green_instead_of_gray"
        yellowThresholdControlName="conversion_rates_yellow_threshold"
        redThresholdControlName="conversion_rates_red_threshold" [form]="form" [isInverted]="true"
        errorName="conversionRatesYellowBelowRed">
      </twng-edit-column-color>
      <div class="form-group">
        <input type="checkbox" id="use_individual_thresholds" formControlName="use_individual_job_stage_thresholds">
        <label for="use_individual_thresholds">
          Set individual thresholds
        </label>
      </div>
      <ng-container *ngIf="!!form.value.use_individual_job_stage_thresholds">
        <hr />
        <div style="margin: 1rem 0rem;">
          Change only values that you wish to <br>
          be different from general thresholds
        </div>
        <div *ngFor="let stage of jobStages">
          <twng-edit-column-color subtitle="{{stage}}" yellowThresholdControlName="yellow" redThresholdControlName="red"
            [form]="jobStageControl(stage)" [isInverted]="true" errorName="{{stage + 'YellowBelowRed'}}">
          </twng-edit-column-color>
        </div>
      </ng-container>
    </form>
  </twng-modal-body>
  <twng-modal-footer>
    <span class="text-danger" *ngIf="isDemo">
      Cannot save configuration in demo mode.
    </span>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="form.disabled || form.invalid || isDemo">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>