import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { WallApiService } from '../../services/wall-api.service'
import { WallDataPaginatedAllJobsFilter, WallDataPaginatedTabFilter } from '../../actions/wall.actions'
import { endOfWeek, startOfWeek } from 'date-fns'
import { eventStats } from './interview-event-collapser'

@Component({
  selector: 'twng-collapsed-calendar',
  templateUrl: './collapsed-calendar.component.html',
  styleUrls: ['./collapsed-calendar.component.scss'],
})
export class CollapsedCalendarComponent implements OnInit, OnDestroy {
  private currentFilterInfo: WallDataPaginatedTabFilter | WallDataPaginatedAllJobsFilter

  private sub = new Subscription()
  isLoading: boolean
  stats: { today: number, tomorrow: number, later: number }

  constructor(
    private wallApi: WallApiService,
    private cd: ChangeDetectorRef,
    private store: Store,
  ) { }

  async ngOnInit() {
    // any time filters are changed, we need to tell calendar to refetch events
    this.sub.add(this.wallApi.currentlyVisibleRelevantFilters$.subscribe(info => {
      this.currentFilterInfo = info
      this.setLoading(true)
      this.wallApi
        .getUpcomingInterviews(this.currentFilterInfo, startOfWeek(new Date()), endOfWeek(new Date()))
        .subscribe(events => {
          this.stats = eventStats(events, new Date())
          this.setLoading(false)
        })
    }))
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  private setLoading(newValue: boolean) {
    this.isLoading = newValue
    this.cd.markForCheck()
  }
}
