import { Component, OnInit } from '@angular/core'
import { Job } from '../../models/job'
import { NgbActiveModal, NgbModalOptions, } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { UpdateRequestAllJobsToLoad } from '../../actions/layout.actions'
import { UpdateTab } from '../../actions/tabs.actions'
import { selectVisibleJobsInCurrentWall } from '../../reducers'

@Component({
  selector: 'twng-sort-jobs-modal',
  templateUrl: './sort-jobs-modal.component.html'
})
export class SortJobsModalComponent implements OnInit {
  jobs$: Observable<Job[]>

  constructor(
    private store: Store,
    public activeModal: NgbActiveModal,
  ) {
  }

  tabModalOptions: NgbModalOptions = {
    backdrop: 'static',
  }

  async ngOnInit() {
    this.jobs$ = this.store.select(selectVisibleJobsInCurrentWall)
    this.store.dispatch(new UpdateRequestAllJobsToLoad(true))
  }

  closeModal(): void {
    this.activeModal.close()
  }

  reorderJobs(event, jobs) {
    const reorderedJobs = jobs.filter(j => j.id !== event.job.id)
    reorderedJobs.splice(event.newPosition, 0, event.job)

    const jobsWithUpdatedPosition = reorderedJobs.map((j, i) => ({
      ...j,
      position: i,
    }));

    const new_tab = {
      ...event.tab,
      job_order: jobsWithUpdatedPosition.map(j => j.id),
    }

    this.store.dispatch(
      new UpdateTab({
        tab: new_tab,
        oldTab: event.tab,
      }),
    )
  }
}
