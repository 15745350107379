<div #widget class="widget" *ngIf="data">
  <div class="header">
    <twng-chart-label [label]="label" 
      [chartInfo]="(dataSources && dataSources[dataSource]) ? dataSources[dataSource].description: null">
    </twng-chart-label>
  </div>

  <div class="table-responsive overflow-auto" *ngIf="data.length">
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let activity of activityLevels" ngbTooltip="{{ getTooltipTextForActivity(activity, store) }}">{{ activity }}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of data">
          <td scope="row">{{ item.name }}</td>
          <ng-container *ngFor="let serie of getSeries(item)">
            <td class="text-center">
              <button *ngIf="serie.value" class="grid-button" (click)="sliceClicked(serie)"
                [ngStyle]="{'color': color(serie)}">
                {{ serie.value }}
              </button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>

  <span *ngIf="!data.length" class="empty-data-message">No active candidates found matching the given criteria.</span>
</div>
