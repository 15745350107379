<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      Reorder Tabs in Executive Dashboard
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <form appDroppable (dropping)="updateTabsOrder($event)" [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
      <twng-reorderable-input [allEntries]="allEntries" formControlName="tabs">
      </twng-reorderable-input>
    </form>
  </twng-modal-body>
  <twng-modal-footer>
    <span class="text-danger" *ngIf="isDemo">
      Cannot save configuration in demo mode.
    </span>
    <button type="button" class="btn btn-primary" (click)="save()"
      [disabled]="form?.disabled || form?.invalid || isDemo">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>