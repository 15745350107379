import { Chart } from '../../../dashboard/models/dashboard-chart'
import { Component, Input, OnChanges } from '@angular/core'
import { NgxChartsRecord } from '../../../dashboard/reducers/analytics.reducer'
import { TitleCasePipe } from '@angular/common'
import { find } from 'lodash-es'
import { getActivityLevelName } from '../../models/activity-day-categories'
import chroma from 'chroma-js'

@Component({
  selector: 'twng-collapsed-chart',
  templateUrl: './collapsed-chart.component.html',
  styleUrls: ['./collapsed-chart.component.scss'],
  providers: [TitleCasePipe]
})
export class CollapsedChartComponent implements OnChanges {
  @Input()
    chart: Chart
  @Input()
    colors: { name: string, value: string }[]

  titleCasePipe = new TitleCasePipe()

  constructor() {
  }

  ngOnChanges() {
    if (this.chart?.data_source === 'activity-stage') {
      this.chart.name = this.chart.name.split('by')[0]
      this.chart.data.data = this.chart.data.data.map((data) => {
        const activityLabel = this.titleCasePipe.transform(getActivityLevelName(data.label))
        data.tooltip = `${activityLabel} (${data.name})`

        return data
      })
    }
  }

  colorClass(row): string {
    return `box-${this.colors[row.name]}`
  }

  color(row: NgxChartsRecord): string {
    return find(this.colors, {name: row.name})?.value || "#666"
  }

  lightColor(row: NgxChartsRecord): chroma.Color {
    return chroma(this.color(row)).alpha(0.2)
  }
}
