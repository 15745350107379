<div class="tw-mt-4 tw-mx-8 tw-flex tw-min-h-[80vh]">
  <aside class="tw-bg-cq-gray-50 tw-pl-4 tw-pt-3 tw-p-2 tw-w-1/4 tw-rounded-md">
    <h3 class="tw-text-xl tw-mb-2 tw-text-cq-primary-800">
      Question History<a (click)="deleteAllHistory()" class="hover:tw-underline tw-cursor-pointer tw-ml-2 tw-text-sm">(clear all)</a>
    </h3>
    <div *ngFor="let question of history" class="Question tw-group tw-my-2 tw-flex tw-items-center tw-justify-between">
      <a (click)="setQuestion(question.message)" class="hover:tw-text-cq-primary-600 tw-cursor-pointer tw-mr-1 app-break-word">
        {{ question.message.slice(0, 200) }}<span *ngIf="question.message.length > 200">&hellip;</span>
      </a>
      <i class="fa-solid fa-trash tw-text-cq-negative-600 !tw-hidden group-hover:!tw-block tw-cursor-pointer" (click)="deleteQuestion(question.id)"></i>
    </div>
  </aside>
  <div class="tw-flex tw-flex-col tw-items-center tw-w-3/4">
    <div class="tw-p-4 tw-h-fit tw-w-full">
      <div class="tw-mx-auto">
        <div class="tw-flex tw-justify-center tw-items-center">
          <div class="tw-flex tw-flex-wrap tw-justify-center">
            <div *ngFor="let example of exampleQuestions" (click)="setQuestion(example)"
                class="tw-w-56 tw-border tw-rounded tw-px-4 tw-py-3 tw-mx-2 tw-mb-4 tw-text-gray-500 hover:tw-bg-gray-100 tw-cursor-pointer">
               {{ example }}
            </div>
          </div>
        </div>
        <div class="QuinInput tw-flex tw-items-end tw-mx-auto tw-w-full tw-max-w-[610px] tw-my-6">
          <div contenteditable="plaintext-only" (keydown.enter)="$event.preventDefault(); handleSubmit(inputText.textContent)" 
            (keydown)="handleInputEvents($event)" (paste)="handleInputEvents($event)"
            class="focus:tw-outline-none tw-flex-grow tw-h-fit tw-bg-white tw-border tw-rounded-md tw-border-cq-gray-200 tw-p-2 tw-w-[85%] tw-max-h-64 tw-overflow-auto"
            [ngClass]="{'!tw-border-red-500': requiredError}"
            placeholder="Enter a question about your hiring data"
            #inputText>
          </div>
          <button type="submit" (click)="handleSubmit(inputText.textContent)" [disabled]="isLoading" title="Ask Quin"
            class="tw-box-border tw-block tw-not-italic tw-h-fit tw-border hover:tw-bg-gray-100 tw-rounded tw-text-white tw-ml-1 disabled:tw-opacity-50 tw-text-nowrap">
            <img src="/assets/ask-quin.png" alt="Ask Quin" width="90">
          </button>
        </div>

        <div class="Output tw-max-w-[960px] tw-mx-auto">
          <div *ngIf="!isLoading && !isValid"
            class="tw-bg-cq-negative-50 tw-border tw-rounded-md tw-p-4 tw-border-cq-negative-600">{{ invalidText }}</div>
            
          <div *ngIf="!isLoading && isValid && question" class="tw-p-4 tw-bg-cq-primary-50 tw-rounded tw-border tw-flex">
            <i class="fa-regular fa-comment tw-text-4xl tw-ml-1 tw-mr-4 tw-mt-1 text-[#123262] tw-block tw-width-8"></i>
            <div class="Question tw-float-right tw-w-full tw-mt-1 app-break-word">{{ question }}</div>
          </div>

          <div *ngIf="!isLoading && isValid && responseText"
            class="QuinOutput tw-p-4 tw-py-6 tw-my-6 tw-mx-auto tw-border tw-bg-cq-primary-50 tw-rounded-md">
              <img class="tw-float-left tw-mr-2 -tw-mt-2 -tw-ml-2" src="/assets/q.png" width="60" />
            <div class="ResponseContainer tw-pr-4" [innerHtml]="responseText"></div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && isValid && charts" class="tw-mb-2 tw-text-center">In generating the above answer, Quin consulted the following:</div>

      <div *ngIf="!isLoading && isValid && charts" class="tw-flex tw-justify-evenly tw-flex-wrap tw-bg-cq-primary-50 tw-rounded-md tw-pb-10 tw-pt-6">
        <div *ngFor="let chart of charts" class="tw-w-[45%] tw-h-[24rem] tw-mt-4 tw-bg-white tw-mb-16">
          <twng-chart-wrapper [chart]="chart">
          </twng-chart-wrapper>
          <div class="tw-h-14	tw-w-full tw-flex tw-items-center tw-justify-center	tw-rounded-b	tw-bg-cq-gray-50">
            <twng-chart-filters [filters]="chart.filters" [chart]="chart" >
            </twng-chart-filters>
          </div>
        </div>
        <ng-container *ngIf="charts.length === 0">
          <div class="tw-flex tw-justify-center tw-items-center tw-p-4 tw-my-4 tw-bg-white tw-rounded-md tw-text-cq-gray-800 tw-text-xl">
            No data found for that query
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isLoading" class="tw-text-center">Analyzing your hiring data...</div>
    <twng-spinner *ngIf="isLoading"></twng-spinner>
  </div>

  <div *ngIf="!isLoading && canViewDebug && debugJSON" class="tw-hidden tw-flex tw-justify-center tw-m-4">
    <div class="tw-p-4 tw-w-[90%] tw-border tw-border-cq-gray-600 tw-bg-cq-gray-50 tw-rounded-md tw-font-mono">
      <span class="tw-flex tw-justify-between">
        <span (click)="toggleShowDebug()" class="tw-text-bold">Debug</span>
        <span (click)="toggleShowDebug()" class="tw-text-xs tw-text-cq-primary-600 tw-underline tw-cursor-pointer">
          {{ showDebug ? "Hide" : "Show" }}
        </span>
      </span>
      <ng-container *ngIf="showDebug">
        <pre *ngFor="let item of debugJSON">{{ item | json }}</pre>
      </ng-container>
    </div>
  </div>
</div>
