import { Subscription } from 'rxjs'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../reducers'
import { UpdateCollapseAllJobs } from '../actions/layout.actions'

@Component({
  selector: 'twng-collapse-jobs',
  template: `
    <button class="btn btn-light btn-sm tw-ml-2 data-html2canvas-ignore" (click)="toggleCollapseJobs()" [class.active]="collapseAllJobs">
      <i class="fas fa-bars"></i>
      <span class="tw-mr-1">{{ collapseAllJobs ? 'Expand All' : 'Collapse All' }}</span>
    </button>
  `,
  styles: [
    `
      span {
        margin: 0.3rem;
      }
    `,
  ],
})
export class CollapseJobsComponent implements OnInit, OnDestroy {
  collapseAllJobs: boolean
  jobIds: string[] | number[]
  collapsedJobsByIdAndJobsSub: Subscription
  collapseAllJobsSub: Subscription

  constructor(private store: Store<fromWall.State>) { }

  ngOnInit(): void {
    this.collapsedJobsByIdAndJobsSub = this.store
      .pipe(select(fromWall.selectJobIds))
      .subscribe(jobIds => {
        this.jobIds = jobIds
      })

    this.collapseAllJobsSub = this.store
      .pipe(select(fromWall.selectCollapseAllJobs))
      .subscribe(collapseAllJobs => {
        this.collapseAllJobs = collapseAllJobs
      })
  }

  ngOnDestroy(): void {
    this.collapsedJobsByIdAndJobsSub.unsubscribe()
    this.collapseAllJobsSub.unsubscribe()
  }

  // toggleCollapseJobs(collapse: boolean): void {
  toggleCollapseJobs(): void {
    const collapse = !this.collapseAllJobs

    const jobsCollapsedByJobId = {}
    this.jobIds.forEach(jobId => {
      jobsCollapsedByJobId[jobId] = collapse
    })
    this.store.dispatch(
      new UpdateCollapseAllJobs({ collapseAllJobs: !this.collapseAllJobs }),
    )
  }
}
