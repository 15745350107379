import { Actions, ofType } from '@ngrx/effects'
import { CacheService } from '../../services/cache.service'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Chart } from '../../../dashboard/models/dashboard-chart'
import { ExecutiveDashboardActionsTypes, SendJobStatusUpdateSuccess } from '../../actions/executive-dashboard.actions'
import { JobApplicationActionTypes, UpdateJobApplicationStageSuccess } from '../../actions/job-applications.actions'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { UpdateWallSummaryMode } from '../../actions/layout.actions'
import { WallApiService } from '../../services/wall-api.service'
import { WallDataPaginatedAllJobsFilter, WallDataPaginatedTabFilter } from '../../actions/wall.actions'
import { WallSummaryMode, wallSummaryModeItem } from '../../reducers/layout.reducer'
import { collapseSeries } from './wall-summary-collapser'
import { distinctUntilChanged } from 'rxjs/operators'
import { selectWallSummaryMode } from '../../reducers'

@Component({
  selector: 'twng-wall-summary-wrapper',
  templateUrl: './wall-summary-wrapper.component.html',
  styleUrls: ['./wall-summary-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WallSummaryWrapperComponent implements OnInit, OnDestroy {
  collapsed = false;
  private sub = new Subscription()
  widgets: Record<string, Chart>
  collapsedActivityByStage: Chart
  info: WallDataPaginatedTabFilter | WallDataPaginatedAllJobsFilter
  activityColors: { name: string, value: string }[]
  jobHealthColors: { name: string, value: string }[]

  constructor(
    private store: Store,
    private wallApi: WallApiService,
    private cd: ChangeDetectorRef,
    protected cacheService: CacheService,
    private actions: Actions
  ) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.store.select(selectWallSummaryMode)
        .pipe(distinctUntilChanged())
        .subscribe(val => {
          this.cd.markForCheck()
          this.collapsed = val === WallSummaryMode.Collapsed
        }))

    this.sub.add(this.wallApi.currentlyVisibleRelevantFilters$.subscribe(info => {
      this.info = info
      this.wallApi.getWallCharts(info)
        .subscribe(widgets => {
          this.widgets = widgets.charts
          this.refreshCollapsedActivityByStage()
        })
    }))
    this.sub.add(this.cacheService.activityColors$.subscribe(activityColors => {
      this.activityColors = activityColors
      this.refreshCollapsedActivityByStage()
    }))
    this.sub.add(this.cacheService.jobStatusColors$.subscribe(jobStatusColors => {
      this.jobHealthColors = jobStatusColors
    }))

    this.subscribeToStoreActions()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  refreshCollapsedActivityByStage() {
    if (!this.widgets || !this.activityColors) {
      this.collapsedActivityByStage = null
    } else {
      const names = this.activityColors.map(row => row.name)
      this.collapsedActivityByStage = collapseSeries(this.widgets.activity_by_stage, names)
    }
    this.cd.markForCheck()
  }

  expand() {
    this.store.dispatch(new UpdateWallSummaryMode(WallSummaryMode.Normal))
    localStorage.setItem(wallSummaryModeItem, WallSummaryMode.Normal.toString())
  }

  collapse() {
    this.store.dispatch(new UpdateWallSummaryMode(WallSummaryMode.Collapsed))
    localStorage.setItem(wallSummaryModeItem, WallSummaryMode.Collapsed.toString())
  }

  subscribeToStoreActions() {
    this.sub.add(this.actions.pipe(
      ofType<SendJobStatusUpdateSuccess>(ExecutiveDashboardActionsTypes.SendJobStatusUpdateSuccess)
    ).subscribe(
      async () => {
        this.callWallCharts()
      }
    ))

    this.sub.add(this.actions.pipe(
      ofType<UpdateJobApplicationStageSuccess>(JobApplicationActionTypes.UpdateJobApplicationStageSuccess)
    ).subscribe(
      async () => {
        this.callWallCharts()
      }))
  }

  async callWallCharts() {
    const widgets = await this.wallApi.getWallCharts(this.info).toPromise()
    this.widgets = widgets.charts
    this.refreshCollapsedActivityByStage()
  }
}
