import { ToastrService } from 'ngx-toastr'
import { isEmpty as _isEmpty } from 'lodash-es'

import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import * as fromWall from '../reducers'
import { ActionError, toasterOnActionPromise } from '../../shared/utils/store.utils'
import { Actions } from '@ngrx/effects'
import { AppConfigService } from '../services/app-config.service'
import { CreateTab, TabActionTypes, UpdateTab } from '../actions/tabs.actions'
import { INITIAL_ALL_JOBS_FILTERS, JobFilters } from '../reducers/layout.reducer'
import { SegmentService } from '../../core/services/segment.service'
import { Tab } from '../models/tab'
import { atsId } from '../models/types'

@Component({
  selector: 'twng-tab-form',
  styleUrls: ['./tab-form.component.scss'],
  templateUrl: './tab-form.component.html',
})
export class TabFormComponent implements OnInit {
  tab: Tab
  jobFilters = INITIAL_ALL_JOBS_FILTERS
  jobIds: atsId[] = null
  tabForm: UntypedFormGroup

  constructor(
    private store: Store<fromWall.State>,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
    private actions: Actions,
  ) {
    this.tab = {
      id: undefined,
      name: undefined,
      position: undefined,
      ...INITIAL_ALL_JOBS_FILTERS
    }
    this.tabForm = this.formBuilder.group({
      name: ['', Validators.required],
    })
  }

  ngOnInit(): void { }

  updateJobFilters(jobFilters: JobFilters): void {
    this.jobFilters = jobFilters
  }

  updateJobs(jobIds: atsId[]): void {
    this.jobIds = jobIds
  }

  isNew(): boolean {
    return this.tab === undefined || this.tab.id === undefined
  }

  isCustomTab(): boolean {
    return !!this.jobIds
  }

  actionName(): string {
    return this.isNew() ? 'Create Tab' : 'Update Tab'
  }

  // this.tabForm will report the form as invalid (even if it has content)
  // when the form has not been touched
  isEditTabFormUntouched(): boolean {
    return this.tabForm.pristine && this.tab.id !== undefined
  }

  tabAction() {
    if (this.isEditTabFormUntouched() || this.tabForm.valid) {
      this.saveTab()
    } else {
      this.toastr.error('Please add a name to the tab')
    }
  }

  buildTab(): Tab {
    if (this.isCustomTab()) {
      return {
        job_ids: this.jobIds,
        department_ids: null,
        office_ids: null,
        external_user_ids: null,
        name: this.tabForm.value.name || this.tab.name,
        position: this.tab.position,
        id: this.tab.id,
        job_priorities: null,
        employment_types: null,
        custom_fields: []
      }
    } else {
      return {
        ...this.jobFilters,
        job_ids: null,
        name: this.tabForm.value.name || this.tab.name,
        position: this.tab.position,
        id: this.tab.id,
      }
    }
  }

  async saveTab() {
    const tab = this.buildTab()
    if (this.isNew()) {
      this.store.dispatch(new CreateTab({ tab }))
      this.segmentService.track("Create Tab (completed)", { smartTab: _isEmpty(tab.job_ids) })
    } else {
      this.store.dispatch(new UpdateTab({ tab, oldTab: this.tab }))
      this.segmentService.track("Edit Tab (completed)", { smartTab: _isEmpty(tab.job_ids) })
    }
    try {
      await toasterOnActionPromise(
        [TabActionTypes.CreateTabSuccess, TabActionTypes.UpdateTabSuccess],
        [TabActionTypes.CreateTabFailure, TabActionTypes.UpdateTabFailure],
        'Tab saved', '', this.toastr, this.actions
      )
      this.activeModal.close('save tab')
    } catch(err) {
      if (!(err instanceof ActionError)) {
        throw err
      }
    }
  }

  setTab(tab: Tab): void {
    this.tab = tab
    if (this.tab.job_ids) {
      this.jobFilters = INITIAL_ALL_JOBS_FILTERS
      this.jobIds = this.tab.job_ids
    } else {
      this.jobFilters = {
        department_ids: tab.department_ids,
        office_ids: tab.office_ids,
        external_user_ids: tab.external_user_ids,
        job_priorities: tab.job_priorities,
        employment_types: tab.employment_types,
        custom_fields: tab.custom_fields,
        job_ids: tab.job_ids,
      }
      this.jobIds = null
    }
    this.tabForm.value.name = this.tab.name
  }

  toggleCustom() {
    if (this.jobIds === null) {
      this.jobIds = []
    } else {
      this.jobIds = null
    }
  }
}
