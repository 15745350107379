<div class="row no-gutters shared-tab-header">
  <div class="col-12">
    <h1 *ngIf="tab">{{ tab.name }}</h1>
    <h3 *ngIf="tab" class="shared-tab">Shared tab</h3>
    <h1 *ngIf="!tab">Loading...</h1>
  </div>
</div>
<div class="tw-flex tw-flex-col tw-mx-8" *ngIf="tab">
  <div>
    <twng-filters-label label="Tab Filters"></twng-filters-label>

    <div class="form-inline tw-pb-3">
      <tab-badges [tab]="tab"></tab-badges>

      <twng-collapsable-save-icon
        placeholder="New tab name"
        tooltip="Save tab to my wall"
        (saveClicked)="saveToMyTabs($event)">
      </twng-collapsable-save-icon>
    </div>
  </div>
  <div *ngIf="account && user">
    <twng-candidate-filters [account]="account" [user]="user"></twng-candidate-filters>
  </div>
</div>

<div class="row no-gutters">
  <twng-num-jobs [numJobs]="numVisibleJobs"></twng-num-jobs>
  <div class="sorting-actions">
    <twng-collapse-jobs></twng-collapse-jobs>
  </div>
</div>

<twng-jobs (numTotalJobs)="numVisibleJobs = $event" [filters]="filter$ | async" (tabInfoAvailable)="tabInfoAvailable($event)">
  
</twng-jobs>

<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>