<div class="collapsed-calendar d-flex align-items-center justify-content-between w-100" style="min-height: 4em;">
  <div style="min-height: 4em;">
    <div class="title tw-pt-2 tw-pb-1">Upcoming Interviews</div>
    <div class="calendar-stats" *ngIf="stats">
      <label>TODAY:</label>
      <span class="value tw-ml-1 tw-mr-4">{{stats.today}}</span>
      <label>TOMORROW:</label>
      <span class="value tw-ml-1 tw-mr-4">{{stats.tomorrow}}</span>
      <label>LEFT THIS WEEK:</label>
      <span class="value tw-ml-1 tw-mr-4">{{stats.later}}</span>
    </div>
  </div>
</div>
