import { Actions } from '@ngrx/effects'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ExecutiveDashboardActionsTypes,
  ReorderExecutiveDashboardTabs
} from '../../../wall/actions/executive-dashboard.actions'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ReorderableEntry } from '../../../shared/components/reorderable-input/reorderable-input.component'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { selectExecutiveDashboardTabs } from '../../../wall/reducers'
import { selectImmediately, toasterOnActionPromise } from '../../../shared/utils/store.utils'

@Component({
  selector: 'twng-reorder-tabs-modal',
  templateUrl: './reorder-tabs-modal.component.html',
  styleUrls: []
})
export class ReorderTabsModalComponent implements OnInit {

  isDemo = window.twng_demo
  form: UntypedFormGroup
  allEntries: ReorderableEntry<number>[]

  constructor(
    private store: Store,
    private actions: Actions,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private changeDetector: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    let tabs = await selectImmediately(this.store, selectExecutiveDashboardTabs)
    tabs = tabs.filter(tab => !tab.default_tab)
    this.form = new UntypedFormGroup({
      tabs: new UntypedFormControl(tabs.map(tab => tab.id))
    })
    this.allEntries = tabs.map(tab => ({
      display: tab.name,
      value: tab.id,
    }))
  }

  async save() {
    const tabIds: number[] = this.form.value.tabs
    this.form.disable()
    this.store.dispatch(new ReorderExecutiveDashboardTabs(tabIds))
    await toasterOnActionPromise(
      [ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabsSuccess],
      [ExecutiveDashboardActionsTypes.ReorderExecutiveDashboardTabsFailure],
      'New order is saved',
      'Failed to save new order',
      this.toastr,
      this.actions
    )
    this.close()
  }

  private close() {
    this.modal.close()
  }

  updateTabsOrder(event) {
    const selectedEntry = this.allEntries.find(e => e.value === event.value)
    const reorderedEntries = [...this.allEntries].filter(e => e.value !== event.value)
    reorderedEntries.splice(event.newPosition, 0, selectedEntry)
    this.allEntries = reorderedEntries

    this.form.controls.tabs.setValue(reorderedEntries.map(e => e.value))
    this.changeDetector.markForCheck()
  }
}
