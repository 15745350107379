import { BehaviorSubject, Observable } from 'rxjs'

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { CacheService } from '../../../../wall/services/cache.service'
import { ExternalUser } from '../../../../wall/models/external-user'
import { FilterOption } from '../generic-filter/filter-option.interface'
import { map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'twng-recruiter-filter',
  templateUrl: './recruiter-filter.component.html',
  styleUrls: ['./recruiter-filter.component.scss'],
})
export class RecruiterFilterComponent implements OnInit {

  selectedRecruiter$: BehaviorSubject<ExternalUser[]> = new BehaviorSubject([])
  recruiters$: Observable<ExternalUser[]>

  @Input()
  // Selected Recruiters ID in the store
    selectedRecruitersIds: string[]

  @Output()
    filterChange = new EventEmitter<FilterOption[]>()

  constructor(private cacheService: CacheService) {
  }

  ngOnInit(): void {
    this.recruiters$ = this.cacheService.recruiters$.pipe(
      tap(recruiters => this.loadSelectedRecruiter(recruiters, this.selectedRecruitersIds)),
      switchMap((allRecruiters: ExternalUser[]) => this.selectedRecruiter$.pipe(
        map((selectedRecruiters: ExternalUser[]) =>
          allRecruiters.filter(r => !selectedRecruiters.find(sr => sr.id === r.id))
        )
      ))
    )
  }

  /**
   * When the component is created, preselect the recruiters already selected from other tabs.
   *
   * @param externalRecruiter
   * @param recruiterIds
   */
  loadSelectedRecruiter(externalRecruiter: ExternalUser[], recruiterIds: string[]): void {
    const recruiters = externalRecruiter.filter(recruiter => recruiterIds.includes(recruiter.id))
    this.selectedRecruiter$.next(recruiters)
  }

  recruiterSelected(recruiter: FilterOption) {
    const selectedRecruiter = this.selectedRecruiter$.value
    this.selectedRecruiter$.next([...selectedRecruiter, recruiter as ExternalUser])
    this.emitFilterValue()
  }

  removeRecruiter(recruiter: FilterOption) {
    const selectedRecruiter = this.selectedRecruiter$.value.filter((r: ExternalUser) => r.id !== recruiter.id)
    this.selectedRecruiter$.next(selectedRecruiter)
    this.emitFilterValue()
  }

  emitFilterValue() {
    setTimeout(() => this.filterChange.emit(this.selectedRecruiter$.value))
  }

  reset() {
    this.selectedRecruiter$ = new BehaviorSubject([])
  }
}
