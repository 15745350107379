import { Observable, Subscription } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

import { Actions, ofType } from '@ngrx/effects'
import { ActivatedRoute } from '@angular/router'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { Account } from '../../models/account'
import { AppConfigService } from '../../services/app-config.service'
import {
  CreateTab, CreateTabFailure, CreateTabSuccess, TabActionTypes
} from '../../actions/tabs.actions'
import { Tab } from '../../models/tab'
import { User } from '../../models/user'
import { WallApiService } from '../../services/wall-api.service'
import { WallDataPaginatedTabFilter } from '../../actions/wall.actions'
import { initWallIfNecessary, loadJobStatusesIfPossible } from '../../../shared/utils/store.utils'
import { selectAccount, selectUser } from '../../../reducers'


@Component({
  selector: 'twng-wall-shared',
  templateUrl: './wall-shared.component.html',
  styleUrls: ['./wall-shared.component.scss']
})
export class WallSharedComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription()
  filter$: Observable<WallDataPaginatedTabFilter>
  tab: Tab
  dataLoaded$: Observable<boolean>

  account: Account
  user: User

  numVisibleJobs: number | undefined

  constructor(
    private wallApi: WallApiService,
    private store: Store,
    private route: ActivatedRoute,
    private actions: Actions,
    public appConfig: AppConfigService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    initWallIfNecessary(this.store)
    this.subscriptions.add(this.route.paramMap.subscribe(
      params => {
        const tabId = params.get('tabId')
        const slug = params.get('sharedKey')
        this.filter$ = this.wallApi.getSharedTabJobFilters$(tabId, slug)
      }
    ))
    this.subscriptions.add(this.actions.pipe(
      ofType(TabActionTypes.CreateTabSuccess, TabActionTypes.CreateTabFailure)
    ).subscribe((action: CreateTabSuccess | CreateTabFailure) => {
      this.handleSaveTabsFinished(action)
    }))
    this.subscriptions.add(this.store.select(
      selectAccount
    ).subscribe(
      account => this.account = account
    ))
    this.subscriptions.add(this.store.select(
      selectUser
    ).subscribe(
      user => this.user = user
    ))

    loadJobStatusesIfPossible(this.store, this.appConfig)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  tabInfoAvailable(tab: Tab): void {
    this.tab = tab
  }

  saveToMyTabs(name: string) {
    if (name.length === 0) {
      this.toastr.error("Name must not be empty", "Invalid new tab name")
    } else {
      this.store.dispatch(
        new CreateTab({
          tab: {
            ...this.tab,
            name,
            id: undefined,
          }
        })
      )
    }
  }

  private handleSaveTabsFinished(action: CreateTabSuccess | CreateTabFailure) {
    switch (action.type) {
      case TabActionTypes.CreateTabFailure: {
        this.toastr.error('Error saving tab')
        break
      }
      case TabActionTypes.CreateTabSuccess: {
        this.toastr.success('New tab name: ' + action.payload.tab.name, "Tab saved")
        break
      }
    }
  }
}
