import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-filters-label',
  templateUrl: './filters-label.component.html',
  styleUrls: ['./filters-label.component.scss']
})
export class FiltersLabelComponent {

  @Input()
    tooltipPrefix: string

  @Input()
    showTooltip: boolean

  @Input()
    label: string
}
