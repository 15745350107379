import { Directive, HostListener, Input } from '@angular/core';
import { RatingNumber } from '../../../../wall/models/rating-number';
import { Store } from '@ngrx/store';
import { UpdateCandidateFilters } from '../../../../wall/actions/layout.actions';

@Directive({
  selector: '[setRatingFilter]'
})
export class SetRatingFilterDirective {

  @HostListener('click')
  setPriorityFilter() {
    this.store.dispatch(new UpdateCandidateFilters({
      rating: this.selected ?
        this.ratingFilters.filter(ratingNumber => ratingNumber !== this.ratingNumber) :
        [...this.ratingFilters, this.ratingNumber]
    }))
  }

  @Input()
    ratingNumber: RatingNumber

  @Input()
    ratingFilters: RatingNumber[]

  @Input()
    selected = false

  constructor(private store: Store) {
  }

}
