import { InterviewCalendarEvent } from "../../models/interview"
import { addDays, isAfter, isSameDay } from "date-fns"
import { groupBy, omit, reduce, values } from "lodash-es"

export const rollupEvents = (allEvents: InterviewCalendarEvent[]) => (
  values(groupBy(allEvents, e => [e.start_time, e.end_time, e.mine]))
    .map(events => {
      if (events.length === 1) {
        return events[0]
      } else {
        return {
          ...omit(events[0], ['id']),
          subEvents: events,
          count: events.length,
        }
      }
    })
)

export const eventStats = (events: InterviewCalendarEvent[], now: Date) => {
  const today = now
  const tomorrow = addDays(today, 1)

  return reduce(events, (acc, e) => {
    const start = new Date(e.start_time)

    return {
      today: acc.today + (isSameDay(start, today) ? 1 : 0),
      tomorrow: acc.tomorrow + (isSameDay(start, tomorrow) ? 1 : 0),
      later: acc.later + (isAfter(start, now) ? 1 : 0),
    }
  }, { today: 0, tomorrow: 0, later: 0 })
}
