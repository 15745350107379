<div class="tw-py-3">
  <b>{{ title }}</b>
  <div class="text-danger" *ngIf="!isInverted && form.hasError(errorName)">
    Value for "Red Threshold" must be greater than "Yellow Threshold"
  </div>
  <div class="text-danger" *ngIf="isInverted && form.hasError(errorName)">
    Value for "Red Threshold" must be smaller than "Yellow Threshold"
  </div>
  <div class="text-danger" *ngIf="showErrorGreaterThan()">
    Minimum value you can enter is {{ minRequiredValue }}
  </div>
  <div class="text-danger" *ngIf="showErrorLessThan()">
    Maximum value you can enter is {{ maxRequiredValue }}
  </div>

  <div class="form-inline justify-content-between">
    <div class="form-check small-input justify-content-start">
      <input type="checkbox" class="form-check-input" [id]="useGreenInsteadOfGrayControlName"
        [formControl]="getFormControl(useGreenInsteadOfGrayControlName)" *ngIf="(redValue || yellowValue) && !subtitle">
      <label class="form-check-label tw-ml-2" [for]="useGreenInsteadOfGrayControlName">
        <ng-container *ngIf="(redValue || yellowValue) && !subtitle">
          Show as green
          <ng-container *ngIf="yellowValue">
            when {{ comparisonWord }} than yellow threshold
          </ng-container>
          <ng-container *ngIf="redValue && !yellowValue">
            when {{ comparisonWord }} than red threshold
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!redValue && !yellowValue && !subtitle">
          {{ noValueWillBeColoredText }}
        </ng-container>
        <ng-container *ngIf="subtitle">
          {{ subtitle }}
        </ng-container>
      </label>
    </div>

    <div class="form-group small-input">
      <label [for]="myYellowId">
        {{ yellowValueText }}
      </label>
      <input type="number" class="tw-mt-1 form-control"
        [placeholder]="'Ex. ' + (isInverted ? maxPlaceholderValue : minPlaceholderValue)" [id]="myYellowId"
        [formControl]="getFormControl(yellowThresholdControlName)" min="0" [max]="maxValue">
      <button class="btn btn-light tw-ml-1" type="button" (click)="form.controls[yellowThresholdControlName].reset()">
        Reset
      </button>
    </div>

    <div class="form-group small-input">
      <label [for]="myRedId">
        {{ redValueText }}
      </label>
      <input type="number" class="tw-mt-1 form-control"
        [placeholder]="'Ex. ' + (isInverted ? minPlaceholderValue : maxPlaceholderValue)" [id]="myRedId"
        [formControl]="getFormControl(redThresholdControlName)" min="0" [max]="maxValue">
      <button class="btn btn-light tw-ml-1" type="button" (click)="form.controls[redThresholdControlName].reset()">
        Reset
      </button>
    </div>
  </div>
</div>