<div class="wall-summary-wrapper tw-mt-4 tw-mx-6 tw-pb-3" [class.px-3]="collapsed" [class.pb-3]="!collapsed">
  <div *ngIf="collapsed" class="wall-summary-collapsed-wrapper" (click)="expand()">
    <div class="row" style="min-height: 4rem; width: 100%;">
      <div class="col-sm-3 h-100">
        <twng-collapsed-chart *ngIf="collapsedActivityByStage" [chart]="collapsedActivityByStage"
          [colors]="activityColors"></twng-collapsed-chart>
      </div>
      <div class="col-sm-3 h-100">
        <twng-collapsed-chart [chart]="widgets?.job_health_status" [colors]="jobHealthColors"></twng-collapsed-chart>
      </div>
      <div class="col-sm-6 h-100">
        <twng-collapsed-calendar></twng-collapsed-calendar>
      </div>
    </div>
    <div class="header-actions">
      <button class="actions-button" (click)="expand()">
        <i class="fas fa-chevron-down"></i>
      </button>
    </div>
  </div>

  <div *ngIf="!collapsed" class="wall-summary-expanded-wrapper">
    <div class="wall-summary-expanded" style="height: 400px; width: 100%;">
      <div class="col-sm-3 h-100 activity-container">
        <!-- ignore type error on 'chart' for now -->
        <twng-grid-chart *ngIf="widgets?.activity_by_stage" [label]="widgets.activity_by_stage.name" [status]="widgets.activity_by_stage.data"
          [dataSource]="widgets.activity_by_stage.data_source"
          [candidateDataType]="widgets.activity_by_stage.data_type">
        </twng-grid-chart>
      </div>
      <div class="col-sm-3 h-100 status-container">
        <!-- ignore type error on 'chart' for now -->
        <twng-progress-chart *ngIf="widgets?.job_health_status" [label]="widgets.job_health_status.name" [status]="widgets.job_health_status.data"
          [dataSource]="widgets.job_health_status.data_source"
          [candidateDataType]="widgets.job_health_status.data_type">
        </twng-progress-chart>
      </div>
      <div class="col-sm-6 h-100 calendar-container">
        <twng-portal class="w-100 h-100" portalName="wall-calendar"></twng-portal>
      </div>
    </div>
    <div class="chevron-container">
      <button class="actions-button" (click)="collapse()">
        <i class="fas fa-chevron-up"></i>
      </button>
    </div>
  </div>
</div>