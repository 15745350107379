import { Actions, ofType } from "@ngrx/effects"
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router"
import { Component, OnDestroy, OnInit } from "@angular/core"
import {
  CreateExecutiveDashboardTab, CreateExecutiveDashboardTabFailure, CreateExecutiveDashboardTabSuccess,
  ExecutiveDashboardActionsTypes
} from "../../../wall/actions/executive-dashboard.actions"
import {
  ExecutiveDashboardContentComponent
} from "../executive-dashboard-content/executive-dashboard-content.component"
import { ExecutiveDashboardJobFilters } from "../../../wall/reducers/layout.reducer"
import { ExecutiveDashboardTab, getTabFromUrlParam$ } from "../../../wall/models/executive-dashboard"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap"
import { Store } from "@ngrx/store"
import { ToastrService } from "ngx-toastr"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { findActivatedRouteWithComponent } from "../../../shared/utils/general-utils"
import { first } from 'rxjs/operators'
import { getDefaultExecutiveDashboardTab } from "../../../wall/reducers"
import { selectImmediately } from "../../../shared/utils/store.utils"
import { timer } from "rxjs"

@Component({
  selector: 'twng-add-new-exec-dash-modal',
  templateUrl: './add-new-tab-modal.component.html',
  styleUrls: ['./add-new-tab-modal.component.css'],
})
export class AddNewTabModalComponent implements OnInit, OnDestroy {
  tab: ExecutiveDashboardTab
  private isDestroyed = false

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    custom_fields: new UntypedFormControl(),
    department_ids: new UntypedFormControl(),
    office_ids: new UntypedFormControl(),
    external_user_ids: new UntypedFormControl(),
    job_ids: new UntypedFormControl(),
  })

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store,
    private actions: Actions,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    let execDashRoute: ActivatedRouteSnapshot
    while (!execDashRoute) {
      if (this.isDestroyed) {
        return
      }
      execDashRoute = findActivatedRouteWithComponent(this.route.snapshot, ExecutiveDashboardContentComponent)
      if (!execDashRoute) {
        await timer(100).toPromise()
      }
    }
    this.tab = await getTabFromUrlParam$(execDashRoute.paramMap.get('tabId'), this.store)
      .pipe(first()).toPromise()
    this.updateJobFilters(this.tab)
  }

  isDemo = window.twng_demo

  ngOnDestroy() {
    this.isDestroyed = true
  }

  close() {
    this.activeModal.close()
  }

  async save() {
    this.form.disable()
    const openJobsTab = await selectImmediately(this.store, getDefaultExecutiveDashboardTab())
    this.store.dispatch(new CreateExecutiveDashboardTab({
      ...openJobsTab,
      ...this.form.value,
    }))
    this.actions.pipe(
      ofType(
        ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabFailure,
        ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabSuccess
      ),
      first(),
    ).subscribe((action: CreateExecutiveDashboardTabSuccess | CreateExecutiveDashboardTabFailure) => {
      if (action.type === ExecutiveDashboardActionsTypes.CreateExecutiveDashboardTabSuccess) {
        this.router.navigate(['/executive-tools/executive-dashboard/' + action.payload.id])
        //this.savedSuccessfully()
        this.close()
      } else {
        this.form.enable()
        this.toastr.error("Error saving configuration")
      }
    })
  }

  updateJobFilters(jobFilters: ExecutiveDashboardJobFilters): void {
    this.form.controls['department_ids'].setValue(jobFilters.department_ids)
    this.form.controls['office_ids'].setValue(jobFilters.office_ids)
    this.form.controls['external_user_ids'].setValue(jobFilters.external_user_ids)
    this.form.controls['custom_fields'].setValue(jobFilters.custom_fields)
    this.form.controls['job_ids'].setValue(jobFilters.job_ids)
  }

}
