import * as fromWall from '../reducers'
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { JobFilters } from '../reducers/layout.reducer'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { UpdateAllJobsFilters } from '../actions/layout.actions'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-all-jobs-filters',
  template: `
    <twng-jobs-filters
      [currentJobFilters]="allJobsFilters$ | async"
      (filtersChange)="updateAllJobsFilter($event)"
    ></twng-jobs-filters>
  `,
})
export class AllJobsFiltersComponent implements OnInit {

  allJobsFilters$: Observable<JobFilters>

  constructor(private store: Store<fromWall.State>) {
  }

  ngOnInit() {
    this.allJobsFilters$ = this.store.select(fromWall.selectAllJobsFilters)
  }

  updateAllJobsFilter(filterValues): void {
    this.store.dispatch(new UpdateAllJobsFilters(filterValues))
  }
}
