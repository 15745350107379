import { Observable, iif, of } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { CANDIDATES_BY_STAGE_URL_NAME, getDefaultTab } from '../../wall/reducers/layout.reducer'
import { State, selectUser } from '../../reducers'
import { Tab } from '../../wall/models/tab'
import { initWallIfNecessary } from '../../shared/utils/store.utils'
import { selectAllTabs } from '../../wall/reducers'

@Injectable({ providedIn: 'root' })
export class IsValidWallTab  {
  constructor(private store: Store<State>,
    private router: Router) {
  }

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    _routerStateSnapshot: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const tabId = activatedRouteSnapshot.paramMap.get('tabId')
    if (tabId === 'all-jobs' || tabId === CANDIDATES_BY_STAGE_URL_NAME) {
      return true
    }
    return this.store.select(selectUser).pipe(
      switchMap(user => iif(() => user.enable_allegro_wall, of(true), this.store.select(selectAllTabs).pipe(
        tap(() => {
          initWallIfNecessary(this.store)
        }),
        filter(tabs => tabs.length > 0),
        map((tabs: Tab[]) => {
          if (!this.isValidTabId(tabs, tabId)) {
            const defaultTab = getDefaultTab(tabs)
            this.router.navigateByUrl(`/wall/tabs/${defaultTab.id}`)
            return false
          }
          return true
        })
      )))
    )
  }

  isValidTabId(tabs: Tab[], tabId: string): boolean {
    const parsedTabId = +tabId
    return tabId && !isNaN(parsedTabId) && !!tabs.find(tab => tab.id === parsedTabId)
      || tabId === CANDIDATES_BY_STAGE_URL_NAME
  }
}
