<twng-modal>
  <form [formGroup]="tabForm" (ngSubmit)="tabAction()">
    <twng-modal-header>
      <twng-modal-title slot="header-title">{{actionName() }}</twng-modal-title>
    </twng-modal-header>
    <twng-modal-body>
      <div>
        <div control="form-group">
          <label for="tab_name">Tab Name *</label>
          <input type="text" autocomplete="off" class="form-control" name="tab_name" formControlName="name"
            value="{{ tab.name }}" maxlength="40" required />
        </div>

        <div *ngIf="!isCustomTab()">
          <label class="tw-mt-3">Job Filters</label>
          <twng-jobs-filters [currentJobFilters]="jobFilters" [hideJobName]="true"
            (filtersChange)="updateJobFilters($event)">
          </twng-jobs-filters>

          <div *ngIf="tab.job_priorities" class="tw-mt-2">
            Job Priorities:
            <twng-badge-group [badgeLabels]="tab.job_priorities"></twng-badge-group>
          </div>
          <div *ngIf="tab.employment_types" class="tw-mt-2">
            Employment Types:
            <twng-badge-group [badgeLabels]="tab.employment_types"></twng-badge-group>
          </div>
          <div *ngIf="tab.job_priorities || tab.employment_types" class="alert alert-warning tw-mt-2">
            This tab contains legacy filters, but they are no longer editable.
            If you want to change the filtering behavior, create a new tab that uses the Custom
            Fields
            filter instead.
          </div>
        </div>

        <div *ngIf="isCustomTab() && appConfig.isJobFilterEnabled('jobs')">
          <label class="tw-mt-3">List of Jobs</label>
          <twng-jobs-selector (jobsChange)="updateJobs($event)" [jobIds]="this.jobIds"></twng-jobs-selector>
        </div>

        <p *ngIf="!isCustomTab() && appConfig.isJobFilterEnabled('jobs')" class="tw-mt-3">
          Or
          <button class="btn btn-sm btn-light new-custom" (click)="toggleCustom()">
            Filter by a specific list of jobs
          </button>
        </p>

        <p *ngIf="isCustomTab() && appConfig.isJobFilterEnabled('jobs')" class="tw-mt-3">
          Or you can use
          <button class="btn btn-sm btn-light new-custom" (click)="toggleCustom()">
            Job Filters
          </button>
        </p>
      </div>
    </twng-modal-body>
    <twng-modal-footer>
      <button type="submit" class="button--primary button--large tw-mb-2 tw-mr-2 tw-normal-case tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3">
        {{ actionName() }}
      </button>
    </twng-modal-footer>
  </form>
</twng-modal>