<div class="container" *ngIf="atsName === 'Greenhouse' && user && !user.external_user_id">
  <div class="alert alert-warning fs-unmask">
    Warning: your email {{ user.email }} does not match a known {{ atsName }} user.
    <br>
    Please use an email address that matches a corresponding {{ atsName }} user in order to see jobs.
  </div>
</div>
<div *ngIf="!useAllegroWall(user)">
  <ng-container *ngIf="(wallLoaded$ | async) && tabs; else spinner">
    <div class="app__tabs" #tabsContainer>
      <ul ngbNav #wallNav="ngbNav" class="nav-tabs justify-content-start" (navChange)="updateSelectedTab($event)"
        [activeId]="activeTabId$ | async">

        <!-- ALL JOBS -->
        <li *ngIf="user" [ngbNavItem]="ALL_JOBS_ID_STR" [id]="ALL_JOBS_ID_STR">
          <a ngbNavLink>All Jobs</a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="wallSummaryMode !== wallSummaryModeTypes.CalendarFullscreen">
              <div class="tw-flex tw-flex-col tw-mx-8">
                <div class="tw-pt-4">
                  <div class="filters-wrapper">
                    <twng-all-jobs-filters></twng-all-jobs-filters>
                  </div>
                </div>

                <div class="tw-pt-4">
                  <twng-candidate-filters [account]="account" [user]="user"></twng-candidate-filters>
                </div>
              </div>

              <!-- this component is placed in 2 places in this file: here and in tabs -->
              <twng-wall-summary-wrapper *ngIf="numVisibleJobs"></twng-wall-summary-wrapper>
              <div class="row no-gutters row--sorting fs-unmask">
                <div class="sorting-num-jobs tw-w-fit">
                  <twng-num-jobs [numJobs]="numVisibleJobs"></twng-num-jobs>
                </div>

                <div class="sorting-actions data-html2canvas-ignore tw-z-50 tw-w-fit">
                  <twng-sort-job class="tw-mr-2" [sortingOptions]="allJobsSortingOptions$ | async"
                    (sortingChange)="sortingOptionsChanged($event)"></twng-sort-job>
                  <twng-collapse-jobs></twng-collapse-jobs>
                </div>
              </div>

              <twng-jobs [showFooter]="true" (numTotalJobs)="numVisibleJobs = $event" [filters]="allJobsFilter$ | async"></twng-jobs>
            </ng-container>
          </ng-template>
        </li>

        <!-- TABS -->
        <li *ngFor="let tab of tabs" [ngbNavItem]="tab.id.toString()" [id]="tab.id">
          <a ngbNavLink>{{tab.name}}</a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="wallSummaryMode !== wallSummaryModeTypes.CalendarFullscreen">
              <div class="row no-gutters export-pdf-row" *ngIf="!!pdfOverlayText">
                <div class="col">
                  <div class="export-pdf-header">
                    <span>{{ tab.name }}</span>
                    <span *ngIf="numVisibleJobs">
                      <span class="export-pdf-count-separator">&#8226;</span>
                      <span class="export-pdf-jobs-count fs-unmask">
                        {{ numVisibleJobs }} open job(s) visible with your permissions
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="tw-flex tw-flex-col tw-mx-8">
                <div class="tw-pb-3">
                  <tab-badges [tab]="tab"></tab-badges>

                  <span *ngIf="userCanEditTabs()" class="edit-tabs">
                    <a (click)="editTabModal(tab)" class="actions-button" ngbTooltip="Edit Tab" placement="top auto">
                      <i class="fa-solid fa-pen-to-square fa-fw"></i>
                    </a>
                    <div mwlConfirmationPopover class="d-inline" popoverTitle="Delete Tab"
                      popoverMessage="Do you want to delete the tab {{ tab.name }}?" placement="top"
                      popoverClass="delete-wall-tab" (confirm)="deleteTab(tab)">
                      <a class="actions-button" ngbTooltip="Delete Tab">
                        <i class="fas fa-trash fa-fw"></i>
                      </a>
                    </div>

                    <span class="icon-divider"></span>
                    <a (click)="exportTabToPDF(tab)" class="actions-button" ngbTooltip="Download as PDF"
                      *ngIf="appConfig.canExportWallToPDF()" placement="top auto">
                      <i class="fas fa-download"></i>
                    </a>

                    <span class="icon-divider"></span>

                    <div ngbDropdown class="d-inline-block">
                      <a class="actions-button" ngbTooltip="Share" id="sortMenu" ngbDropdownToggle>
                        <i class="fas fa-share-alt"></i>
                      </a>

                      <div class="filter-dropdown-menu" ngbDropdownMenu aria-labelledby="sortMenu">
                        <button class="dropdown-item" (click)="shareTabModal()">
                          <i class="fas fa-share"></i> Share Tab
                        </button>
                        <button class="dropdown-item" ngxClipboard [cbContent]="getClipboardLink(tab.id, tab.sharable_token)"
                          (click)="copiedNotification()"
                        >
                          <i class="fas fa-link"></i> Send this link to a colleague
                        </button>
                        <button class="dropdown-item tw-flex tw-items-center" (click)="openSendToSlackTab(tab)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="margin-right: 5px;" width="18" height="18"><path d="M94.12 315.1c0 25.9-21.16 47.06-47.06 47.06S0 341 0 315.1c0-25.9 21.16-47.06 47.06-47.06h47.06v47.06zm23.72 0c0-25.9 21.16-47.06 47.06-47.06s47.06 21.16 47.06 47.06v117.84c0 25.9-21.16 47.06-47.06 47.06s-47.06-21.16-47.06-47.06V315.1zm47.06-188.98c-25.9 0-47.06-21.16-47.06-47.06S139 32 164.9 32s47.06 21.16 47.06 47.06v47.06H164.9zm0 23.72c25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06H47.06C21.16 243.96 0 222.8 0 196.9s21.16-47.06 47.06-47.06H164.9zm188.98 47.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06s-21.16 47.06-47.06 47.06h-47.06V196.9zm-23.72 0c0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06V79.06c0-25.9 21.16-47.06 47.06-47.06 25.9 0 47.06 21.16 47.06 47.06V196.9zM283.1 385.88c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06-25.9 0-47.06-21.16-47.06-47.06v-47.06h47.06zm0-23.72c-25.9 0-47.06-21.16-47.06-47.06 0-25.9 21.16-47.06 47.06-47.06h117.84c25.9 0 47.06 21.16 47.06 47.06 0 25.9-21.16 47.06-47.06 47.06H283.1z"/></svg>
                          Share to Slack
                        </button>
                      </div>
                    </div>
                  </span>
                </div>
                <div>
                  <twng-candidate-filters [account]="account" [user]="user"></twng-candidate-filters>
                </div>
              </div>

              <!-- this component is placed in 2 places in this file: here and in all jobs -->
              <twng-wall-summary-wrapper *ngIf="numVisibleJobs"></twng-wall-summary-wrapper>

              <div class="row no-gutters row--sorting">
                <div class="sorting-num-jobs ">
                  <twng-num-jobs [numJobs]="numVisibleJobs"></twng-num-jobs>
                </div>
                <div class="sorting-actions data-html2canvas-ignore">
                  <button class="btn btn-light btn-sm tw-ml-2" (click)="openModalForJobs()" *ngIf="singlePageOfJobs(numVisibleJobs) && (this.canReorderJobs$ | async)">
                    <i class="fas fa-random icon--reorder"></i>
                    <span class="tw-mr-1 fs-unmask tw-z-10">Reorder Jobs</span>
                  </button>

                  <twng-collapse-jobs></twng-collapse-jobs>
                </div>
              </div>
              <twng-spinner-overlay [exporting]="!!pdfOverlayText" [placeholder]="pdfOverlayText"></twng-spinner-overlay>
              <twng-jobs [showFooter]="true" (numTotalJobs)="numVisibleJobs = $event" [filters]="currentTabFilter$ | async"></twng-jobs>
            </ng-container>
          </ng-template>
        </li>

        <li *ngIf="this.account && userCanEditTabs()" [ngbNavItem]="ADD_TAB_ID"
          [id]="ADD_TAB_ID" [disabled]="!account" ngbTooltip="Add Tab" placement="bottom">
          <a ngbNavLink>
            <i class="fas fa-plus icon--tab-add"></i>
          </a>
        </li>

        <li *ngIf="this.account && userCanEditTabs() && this.tabs.length > 1" [ngbNavItem]="SORT_TAB_ID"
          [id]="SORT_TAB_ID" [disabled]="!account" ngbTooltip="Reorder Tabs" placement="bottom">
          <a ngbNavLink>
            <i class="fas fa-random icon--tab-reorder"></i>
          </a>
        </li>

        <li [ngbNavItem]="CANDIDATES_BY_STAGE_STR" [id]="CANDIDATES_BY_STAGE_STR">
          <a ngbNavLink>Candidates by Stage</a>
          <ng-template ngbNavContent>
            <div class="candidates-by-stage-container">
              <twng-candidates-in-stage></twng-candidates-in-stage>
            </div>
          </ng-template>
        </li>
      </ul>

      <ng-container *ngIf="(activeTabId$ | async) !== CANDIDATES_BY_STAGE_STR">
        <twng-portal portalName="wall-calendar"
          *ngIf="wallSummaryMode === wallSummaryModeTypes.CalendarFullscreen"
          class="w-100" style="height:calc(100vh - 14rem);"></twng-portal>
      </ng-container>

      <div [ngbNavOutlet]="wallNav" class="tw-mt-2"></div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="(activeTabId$ | async) !== CANDIDATES_BY_STAGE_STR && !useAllegroWall(user)">
  <twng-portal-tracker portalName="wall-calendar">
    <ng-template appPortalTrackerTemplate>
      <twng-calendar></twng-calendar>
    </ng-template>
  </twng-portal-tracker>
</ng-container>

<ng-template #spinner>
  <twng-spinner></twng-spinner>
</ng-template>


<div class="tw-flex tw-h-[calc(100%-4rem)]" *ngIf="useAllegroWall(user)">
  <iframe class="iframe" [src]="urlSafe" frameborder="0" scrolling="yes" width="100%"
    allow="clipboard-read https://sobrio.loco.crosschq.dev https://sobrio.app.talentwall.io; clipboard-write https://sobrio.app.talentwall.io https://sobrio.loco.crosschq.dev"
    style="flex-grow: 1; border: none; margin: 0; padding: 0;">
  </iframe>
</div>
