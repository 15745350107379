import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store, select } from '@ngrx/store'

import { State, selectUser } from '../../reducers'

@Injectable({ providedIn: 'root' })
export class CanViewNewWallGuard  {
  constructor(private store: Store<State>) { }
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(selectUser),
      map(user =>
        user?.enable_allegro_wall
      ),
      first(config => config)
    )
  }
}
