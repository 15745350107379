import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { isEqual, last } from 'lodash-es'

export interface PortalLocation {
  x: number
  y: number
  width: number
  height: number
}

export function isLocationValid(location: PortalLocation) {
  if (!location) {
    return false
  }
  return (location.x !== 0 || location.y !== 0 || location.width !== 0 || location.height !== 0)
}

interface PortalInfo {
  id: number
  location: PortalLocation
}

// Portals are meant to be used to place components inside other components
// but without putting them as child of those components. You put a portal
// somewhere and it will track its position/location. You can then use portal
// tracker component that will copy position/location of certain portal.
@Injectable({
  providedIn: 'root'
})
export class PortalTrackerService {
  // A list of portals. There may be multiple portals with same name, but the
  // one added last is the active one. Also, each portal has its own ID
  private portals: Record<string, PortalInfo[]> = {}
  // Next id for assignment
  private globalIdCount = 0
  // Emits any changes in portals (changes in size, added, removed, etc)
  private allPortals$ = new Subject<[string, PortalInfo]>()

  constructor() { }

  // returns ID for this portal
  addPortal(name: string, location: PortalLocation): number {
    if (!this.portals[name]) {
      // create empty list of portals with this name, if there are no portals
      // with this name
      this.portals[name] = []
    }
    const info: PortalInfo = {
      id: this.globalIdCount,
      location
    }
    // this portal will be new active portal because it is last
    this.portals[name].push(info)
    // emit this new portal to others
    this.allPortals$.next([name, info])
    // return assigned portal id and increment the id (increment happens after)
    return this.globalIdCount++
  }

  // always returns number. -1 if doesn't exist
  private getPortalIdx(name: string, id: number) {
    return (this.portals[name] || []).findIndex(p => p.id === id)
  }

  updatePortal(name: string, id: number, location: PortalLocation) {
    const portalIdx = this.getPortalIdx(name, id)
    if (portalIdx === -1) {
      console.warn("Portal", name, id, "wasn't found for update")
      return
    }
    const portal = this.portals[name][portalIdx]
    portal.location = location
    // if this is the last added portal, emit event
    if (portalIdx === this.portals[name].length - 1) {
      this.allPortals$.next([name, portal])
    }
  }

  removePortal(name: string, id: number) {
    const portalIdx = this.getPortalIdx(name, id)
    if (portalIdx === -1) {
      console.warn("Portal", name, id, "wasn't found for removal")
      return
    }
    this.portals[name].splice(portalIdx, 1)
    // if this is the last added portal, emit the latest portal in stack. This
    // will emit undefined (which is ok) when last item was removed
    if (portalIdx === this.portals[name].length) {
      this.allPortals$.next([name, last(this.portals[name])])
    }
    // delete key to this portal completely
    if (this.portals[name].length === 0) {
      delete this.portals[name]
    }
  }

  trackPortal$(name: string) {
    return this.allPortals$.pipe(
      filter(portal => portal[0] === name),
      map(infos => infos[1]?.location),
      distinctUntilChanged(isEqual),
    )
  }
}
