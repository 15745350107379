import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appPortalTrackerTemplate]'
})
export class PortalTrackerTemplateDirective {

  constructor(public templateRef: TemplateRef<unknown>) { }

}
