import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { getUniqueId } from '../../../../shared/utils/general-utils'

@Component({
  selector: 'twng-edit-column-color',
  templateUrl: './edit-column-color.component.html',
  styleUrls: ['./edit-column-color.component.css']
})
export class EditColumnColorComponent implements OnInit {
  @Input()
    useGreenInsteadOfGrayControlName: string

  @Input()
    yellowThresholdControlName: string

  @Input()
    redThresholdControlName: string

  @Input()
    noValueWillBeColoredText: string

  @Input()
    yellowValueText: string

  @Input()
    redValueText: string

  @Input()
    maxValue: number

  @Input()
    title: string

  @Input()
    form: UntypedFormGroup

  @Input()
    isInverted = false

  @Input()
    errorName: string

  @Input()
    minPlaceholderValue = 30

  @Input()
    maxPlaceholderValue = 60

  @Input()
    subtitle: string

  minRequiredValue: number
  maxRequiredValue: number

  // id used in html
  myRedId = "edit-color-" + getUniqueId()
  // id used in html
  myYellowId = "edit-color-" + getUniqueId()

  get comparisonWord() {
    return this.isInverted ? 'greater' : 'less'
  }

  get isGreen(): boolean {
    return this.form.value[this.useGreenInsteadOfGrayControlName]
  }

  get yellowValue(): number | undefined {
    return this.form.value[this.yellowThresholdControlName]
  }

  get redValue(): number | undefined {
    return this.form.value[this.redThresholdControlName]
  }

  getFormControl(controlName: string) {
    return this.form.controls[controlName] as UntypedFormControl
  }

  showErrorGreaterThan() {
    const ret = this.form.controls[this.yellowThresholdControlName].hasError('min') ||
                this.form.controls[this.redThresholdControlName].hasError('min')
    if (ret) {
      this.minRequiredValue = this.form.controls[this.yellowThresholdControlName].getError('min')?.min
      this.minRequiredValue ||= this.form.controls[this.redThresholdControlName].getError('min')?.min
    }
    return !!ret
  }

  showErrorLessThan() {
    const ret = this.form.controls[this.yellowThresholdControlName].hasError('max') ||
                this.form.controls[this.redThresholdControlName].hasError('max')
    if (ret) {
      this.maxRequiredValue = this.form.controls[this.yellowThresholdControlName].getError('max')?.max
      this.maxRequiredValue ||= this.form.controls[this.redThresholdControlName].getError('max')?.max
    }
    return !!ret
  }

  constructor() { }

  ngOnInit(): void {
  }

}
