
import { ActivityLevels, getActivityLevelName,
  getTooltipTextForActivity
} from '../../wall/models/activity-day-categories'
import { CacheService } from '../../wall/services/cache.service'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ChartCommonComponent } from './chart-common.component'
import { NgxChartsRecord } from '../reducers/analytics.reducer'
import { Store } from '@ngrx/store'


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-grid-chart',
  templateUrl: './grid-chart.component.html',
  styleUrls: ['./grid-chart.component.scss']
})
export class GridChartComponent extends ChartCommonComponent {
  activityLevels = ActivityLevels
  getTooltipTextForActivity = getTooltipTextForActivity

  constructor(
    public store: Store,
    cacheService: CacheService,
  ) {
    super(store, cacheService)
  }

  getSeries(item: NgxChartsRecord) {
    item.series = item.series.map((s) => {
      s.label = getActivityLevelName(s.label)
      return s
    })
    const allSeries = [];
    this.activityLevels.forEach((activity) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentSerie: any = item.series.find((serie) => activity === serie.label)
      if (currentSerie) {
        currentSerie.series = item.name
        allSeries.push(currentSerie)
      } else {
        const emptySerie = { value: 0, label: activity, series: item.name }
        allSeries.push(emptySerie)
      }
    });
    return allSeries
  }

  color(row: NgxChartsRecord): string {
    const colors = {
      good: '#6AB057',
      fair: '#C4B908',
      poor: '#C54D44',
      inactive: '#999999'
    }
    return colors[row.label] || "#666"
  }
}
