
import { CacheService } from '../../wall/services/cache.service'
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { ChartCommonComponent } from './chart-common.component'
import { NgxChartsRecord } from '../reducers/analytics.reducer'
import { Store } from '@ngrx/store'
import { find } from 'lodash-es'


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'twng-progress-chart',
  templateUrl: './progress-chart.component.html',
  styleUrls: ['./progress-chart.component.scss']
})
export class ProgressChartComponent extends ChartCommonComponent implements OnInit {
  totalJobs: number;

  constructor(
    store: Store,
    cacheService: CacheService,
  ) {
    super(store, cacheService)
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.totalJobs = this.data.reduce((accum,item) => accum + item.value, 0)
  }

  getPercentage(value: number) {
    return `${(value / this.totalJobs * 100).toFixed(0)}%`
  }

  color(row: NgxChartsRecord): string {
    return find(this.customColors, {name: row.name})?.value || "#666"
  }
}
