import { Component, Input, OnInit } from '@angular/core'
import { CustomFieldsService } from '../../../custom-fields/services/custom-fields.service'
import { Dictionary } from '@ngrx/entity'
import { Named } from '../../models/named'
import { Store, select } from '@ngrx/store'
import { Tab } from '../../models/tab'
import { atsId } from '../../models/types'

import * as fromWall from '../../reducers/index'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tab-badges',
  templateUrl: './tab-badges.component.html',
  styleUrls: ['./tab-badges.component.css']
})
export class TabBadgesComponent implements OnInit {
  constructor(private store: Store, public customFieldsService: CustomFieldsService) { }

  @Input()
    tab: Tab

  departmentEntities$ = this.store.pipe(
    select(fromWall.selectDepartmentEntities),
  )
  officeEntities$ = this.store.pipe(
    select(fromWall.selectOfficeEntities),
  )
  externalUserEntities$ = this.store.pipe(
    select(fromWall.selectExternalUserEntities),
  )

  ngOnInit(): void {
  }

  badgeNames(entities: Dictionary<Named>, ids: atsId[]): string[] | null {
    if (!ids) {
      return null
    }
    return ids.map(id => entities[id] && entities[id].name).filter(n => n)
  }

}
