import { ActivatedRoute } from '@angular/router'
import { AddNewTabModalComponent } from '../add-new-tab-modal/add-new-tab-modal.component'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { EditExecutiveDashboardThresholdsModalComponent } from
  '../edit-executive-dashboard-thresholds-modal/edit-executive-dashboard-thresholds-modal.component'
import { ExecutiveDashboardTab } from '../../../wall/models/executive-dashboard'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subscription } from 'rxjs'
import { ReorderTabsModalComponent } from '../reorder-tabs-modal/reorder-tabs-modal.component'
import { SegmentService } from '../../../core/services/segment.service'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'
import { loadExecutiveDashboardIfNecessary } from '../../../shared/utils/store.utils'
import { selectExecutiveDashboardLoaded, selectExecutiveDashboardTabs } from '../../../wall/reducers'
import { selectUser } from '../../../reducers'

@Component({
  selector: 'twng-executive-dashboard-page',
  templateUrl: './executive-dashboard-page.component.html',
  styleUrls: ['./executive-dashboard-page.component.css']
})
export class ExecutiveDashboardPageComponent implements OnInit, OnDestroy {
  isTabLoadingFinished$: Observable<boolean>
  tabs: ExecutiveDashboardTab[]

  isAdmin: boolean

  subs = new Subscription()

  constructor(
    private segmentService: SegmentService,
    private store: Store,
    public route: ActivatedRoute,
    private modal: NgbModal,
  ) { }

  ngOnInit(): void {
    loadExecutiveDashboardIfNecessary(this.store)
    this.isTabLoadingFinished$ = this.store.select(selectExecutiveDashboardLoaded)
    this.subs.add(
      this.store.select(selectExecutiveDashboardTabs).subscribe(tabs => {
        this.tabs = tabs
      })
    )
    this.subs.add(
      this.store.select(selectUser).pipe(first()).subscribe(user => {
        this.isAdmin = user.admin
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  defaultTab() {
    return (this.tabs || []).find(t => t.default_tab)
  }

  openJobsTab() {
    return this.defaultTab()
  }

  closedJobsTab() {
    return this.defaultTab()
  }

  nonDefaultTabs() {
    return this.tabs?.filter(t => !t.default_tab)
  }

  getTabById(tabId: string) {
    return this.tabs?.find(t => t.id === +tabId)
  }

  track(trackKey: string) {
    this.segmentService.track(trackKey)
    // this.segmentService.track('View Executive Dashboard')
  }

  openCreateNewTabModal() {
    this.modal.open(AddNewTabModalComponent)
  }

  openReorderTabsModal() {
    this.modal.open(ReorderTabsModalComponent)
  }

  openConfigureDashModal() {
    this.modal.open(EditExecutiveDashboardThresholdsModalComponent)
  }
}
