import { CanViewDashboardGuard } from './core/guards/can-view-dashboard.guard'
import { CanViewHiringManagementGuard } from './core/guards/can-view-hiring-management.guard'
import { CanViewNewWallGuard } from './core/guards/can-view-new-wall.guard'
import { CanViewWallGuard } from './core/guards/can-view-wall.guard'
import { DashboardComponent } from './dashboard/components/dashboard.component'
import { EditGoalsComponent } from './executive-dashboard/components/edit-goals/edit-goals.component'
import {
  ExecutiveDashboardContentComponent
} from './executive-dashboard/components/executive-dashboard-content/executive-dashboard-content.component'
import {
  ExecutiveDashboardPageComponent
} from './executive-dashboard/components/executive-dashboard-page/executive-dashboard-page.component'
import {
  ExecutiveDashboardSharedTabPageComponent} from
  './executive-dashboard/components/executive-dashboard-shared-tab-page/executive-dashboard-shared-tab-page.component'
import { HiringManagementComponent } from './executive-dashboard/components/hiring-management.component'
import { IframeComponent } from './shared/components/iframe/iframe.component'
import { IsAdminUserGuard } from './core/guards/is-admin-user.guard'
import { IsValidWallTab } from './core/guards/is-valid-wall-tab'
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component'
import { PageNotFoundComponent } from './core/components/page-not-found.component'
import { ProspectsComponent } from './prospects/components/prospects/prospects.component'
import { Route, Routes } from '@angular/router'
import { ScheduledReportsPageComponent } from './user-settings/scheduled-reports-page/scheduled-reports-page.component'
import {
  SharedCustomDashboardComponent
} from './dashboard/components/shared-custom-dashboard/shared-custom-dashboard.component'
import { StageMappingsComponent } from './stage-mappings/components/stage-mappings/stage-mappings.component'
import { TabDescriptor, TabsComponent } from './shared/components/tabs/tabs.component'
import { WallComponent } from './wall/components/wall.component'
import { WallSharedComponent } from './wall/components/wall-shared/wall-shared.component'
import { WallSharedSingleJobComponent } from './wall/components/wall-shared-single-job/wall-shared-single-job.component'
import { apiHost } from './core/http-options'

const mySettingsTabs: TabDescriptor[] = [{
  url: "/settings/my_settings",
  text: "My Settings",
}, {
  url: "/settings/scheduled_reports",
  text: "Scheduled Reports",
  requiredFeatureFlag: 'can_schedule_reports',
}, {
  url: "/settings/auth",
  text: "Authentication",
}]

export const appRoutes: Routes = [
  {
    path: 'shared-executive-tab/:tabId/:sharedKey',
    canActivate: [],
    component: ExecutiveDashboardSharedTabPageComponent,
  },
  {
    path: 'capacity-planning',
    component: IframeComponent,
    data: { url: `//${window.sobrio_host_name}/${window.twng_account?.id}/capacity-planning` }
  },
  {
    path: 'executive-tools',
    component: HiringManagementComponent,
    canActivate: [CanViewHiringManagementGuard],
    children: [
      {
        path: 'capacity-planning',
        component: IframeComponent,
        data: { url: `//${window.sobrio_host_name}/${window.twng_account?.id}/capacity-planning` }
      },
      {
        path: 'funnel-segmentation',
        component: IframeComponent,
        data: { url: `//${window.sobrio_host_name}/accounts/${window.twng_account?.id}/funnel-segmentation` }
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'executive-dashboard',
      },
      {
        path: 'executive-dashboard',
        component: ExecutiveDashboardPageComponent,
        canActivate: [CanViewHiringManagementGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'open_jobs',
          },
          {
            path: 'not_found',
            component: NotFoundPageComponent,
          },
          {
            path: ':tabId',
            component: ExecutiveDashboardContentComponent
          },
          {
            path: 'tabs/:tabId',
            component: HiringManagementComponent
          },
        ]
      }
    ],
  },
  {
    path: 'wall',
    canActivate: [CanViewWallGuard],
    children: [
      {
        path: 'tabs/:tabId',
        canActivate: [IsValidWallTab],
        component: WallComponent
      },
      {
        path: '',
        component: WallComponent,
        // redirectTo: '/wall/tabs/none',
        // pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'legacy_wall',
    canActivate: [CanViewNewWallGuard],
    component: WallComponent,
    data: { isLegacyWall: true },
    children: [
      {
        path: 'tabs/:tabId',
        canActivate: [IsValidWallTab],
        component: WallComponent
      }]
  },
  {
    path: 'wall-shared/:tabId/:sharedKey',
    canActivate: [CanViewWallGuard],
    component: WallSharedComponent,
  },
  {
    path: 'wall-shared-single-job/:jobId',
    canActivate: [CanViewWallGuard],
    component: WallSharedSingleJobComponent,
  },
  { path: 'prospects', component: ProspectsComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CanViewDashboardGuard],
    children: [
      // NOTE: we have to redirect, or checking the current route in chart-filter.effects will fail on default route
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'widget-library/hires',
      },
      {
        path: 'widget-library',
        data: { panel: "company_dashboard", mainNav: 'widget-library' },
        children: [
          {
            path: 'hires',
            component: DashboardComponent,
            data: { subNav: 'hires' }
          },
          {
            path: 'offers',
            component: DashboardComponent,
            data: { subNav: 'offers' }
          },
          {
            path: 'conversion_rates',
            component: DashboardComponent,
            data: { subNav: 'conversion_rates' }
          },
          {
            path: 'interview_activity',
            component: DashboardComponent,
            data: { subNav: 'interview_activity' }
          },
          {
            path: 'other',
            component: DashboardComponent,
            data: { subNav: 'other' }
          },
          {
            path: 'eeoc_and_diversity',
            component: DashboardComponent,
            data: { subNav: 'eeoc_and_diversity' }
          },
          {
            path: 'demographic',
            component: DashboardComponent,
            data: { subNav: 'demographic' }
          },
          {
            path: 'active_pipeline',
            component: DashboardComponent,
            data: { subNav: 'active_pipeline' }
          },
          {
            path: 'sourcing',
            component: DashboardComponent,
            data: { subNav: 'sourcing' }
          },
          {
            path: 'recently_added',
            component: DashboardComponent,
            data: { subNav: 'recently_added' }
          },
          {
            path: 'time_in_stage',
            component: DashboardComponent,
            data: { subNav: 'time_in_stage' }
          },
          {
            path: 'custom',
            component: DashboardComponent,
            data: { subNav: 'custom' }
          },
          {
            path: 'qoh',
            component: DashboardComponent,
            data: { subNav: 'qoh' }
          },
        ]
      },
      {
        path: 'dashboards',
        component: DashboardComponent,
        children: [
          {
            path: 'mine',
            component: DashboardComponent,
            data: { panel: "my_dashboard", tabId: "my_dashboard" }
          },
          {
            path: ':tabId',
            component: DashboardComponent,
          },
        ]
      },
      {
        path: 'quin',
        component: DashboardComponent,
      }
    ]
  },
  {
    path: 'shared-dashboard/:tabId/:sharedKey',
    component: SharedCustomDashboardComponent
  },
  {
    path: 'shared-dashboard/not_found',
    component: NotFoundPageComponent,
  },
  { path: 'stage-mappings', component: StageMappingsComponent, canActivate: [IsAdminUserGuard] },
  { path: 'account/interview-phases', component: StageMappingsComponent, canActivate: [IsAdminUserGuard] },
  {
    path: 'settings',
    component: TabsComponent,
    data: mySettingsTabs,
    children: [
      {
        path: 'my_settings',
        component: IframeComponent,
        data: { url: `${apiHost}/my_settings` }
      },
      {
        path: 'auth',
        component: IframeComponent,
        data: { url: `${apiHost}/users/edit` }
      },
      {
        path: 'scheduled_reports',
        component: ScheduledReportsPageComponent
      }
    ]
  },
  {
    path: 'executive-tools/edit-goals',
    component: EditGoalsComponent
  },

  window.twng_demo ?
    { path: '**', redirectTo: '/wall' } :
    { path: '**',  component: PageNotFoundComponent },
]

export const getAllRoutesPaths = (): string[] => appRoutes
  .filter(({ path }: Route) => path !== '*' && path !== '**')
  .map((route: Route) => `/${route.path}`)
