import { Injectable } from '@angular/core'
import { NotificationService, NotificationType } from '../../shared/services/notification.service'
import { PdfGlobalService } from '../../shared/services/pdf-global.service'
import { PdfService, TwngPdfOptions } from '../../shared/services/pdf.service'
import { Store } from '@ngrx/store'
import { UpdateViewSingleJobOnWall } from '../actions/layout.actions'
import { first } from 'rxjs/operators'
import { jsPDF } from 'jspdf'
import { selectImmediatelySync } from '../../shared/utils/store.utils'
import { selectVisibleJobIdsInCurrentWall } from '../reducers'

@Injectable({
  providedIn: 'root'
})
export class WallPdfService extends PdfService {

  constructor(
    private notifications: NotificationService,
    private store: Store,
    private pdf: PdfGlobalService,
  ) {
    super()
  }

  getAllJobs(): string[] {
    return selectImmediatelySync(this.store, selectVisibleJobIdsInCurrentWall)
  }

  async addJobs(pdf: jsPDF): Promise<void> {
    this.notifications.updateNotification(NotificationType.GeneratingPDF, "PDF generation progress")
    const jobs = this.getAllJobs()

    // Keeps track of the current vertical position on the pdf page
    let heightCounter = this.getLogoSectionDimensions().logoReactHeight

    // Add the header
    const headerSelector = '.export-pdf-row'
    const canvasHeader = await this.generateCanvasFromHTML(headerSelector)
    const headerDimensions = this.getImageDimensions(headerSelector)
    // Add the job canvas element to the pdf
    pdf.addImage(
      canvasHeader,
      'PNG',
      0, // x position on the page
      heightCounter, // y position
      headerDimensions.width,
      headerDimensions.height,
      '', // alias
      'FAST'
    )

    heightCounter += headerDimensions.height
    let doneCounter = 0

    for (const job of jobs) {
      this.store.dispatch(new UpdateViewSingleJobOnWall(job))
      await this.pdf.allComponentsReady$.pipe(first(
        ready => ready && document.getElementById("job-" + job) !== null
      )).toPromise()

      heightCounter = await this.captureSingleJob(pdf, job, heightCounter)
      this.notifications.updateNotification(NotificationType.GeneratingPDF,
        undefined, ++doneCounter, jobs.length)
    }
    this.notifications.closeNotification(NotificationType.GeneratingPDF)
    this.store.dispatch(new UpdateViewSingleJobOnWall())
  }

  protected async generatePDF(elementSelector?: string, pdfOptions?: TwngPdfOptions): Promise<void> {
    const pdf = this.createPDFFile(elementSelector)
    await this.addJobs(pdf)

    const tabName = pdfOptions?.tabName || 'tab'
    const fileName = `talentwall-${tabName} `
    // Download the pdf in the browser
    pdf.save(this.generateFileName(fileName))
  }

  private async captureSingleJob(pdf: jsPDF, jobId: string, heightCounter: number): Promise<number> {
    const pageHeight = pdf.internal.pageSize.getHeight()
    const logoSizes = this.getLogoSectionDimensions()
    const jobSelector = `#job-${jobId}`

    // Generate HTML Element to Canvas
    const canvasImage = await this.generateCanvasFromHTML(jobSelector)

    // Image dimension with aspect ratio
    const jobImageDimensions = this.getImageDimensions(jobSelector)

    // If the job height doesn't fit in the current page,
    // then create a new pdf page
    if ((heightCounter + jobImageDimensions.height) > pageHeight) {
      this.addPage(pdf)
      heightCounter = logoSizes.logoReactHeight
    }

    // Add the job canvas element to the pdf
    pdf.addImage(
      canvasImage,
      'PNG',
      0, // x position on the page
      heightCounter, // y position: below the last element added
      jobImageDimensions.width,
      jobImageDimensions.height,
      '', // alias
      'FAST'
    )
    heightCounter += (jobImageDimensions.height)
    return heightCounter
  }
}
