import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'twng-badge-group',
  template: `
    <span class="badge-group" *ngIf="badgeLabels && badgeLabels.length > 0">
      <span *ngFor="let badgeLabel of badgeLabels"
        class="badge badge-wire tw-w-fit tw-py-1 tw-px-2 tw-rounded-full tw-font-medium tw-text-sm tw-bg-cq-gray-100 tw-text-cq-gray-600">
        {{ badgeLabel }}
      </span>
    </span>
`,
  styles: [
    `
      .badge {
        margin-left: 8px;
      }
    `,
  ],
})
export class BadgeGroupComponent implements OnInit {
  @Input()
    badgeLabels: string[]

  constructor() {}

  ngOnInit(): void {}
}
