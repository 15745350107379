<div class="candidate-filters fs-unmask tw-flex tw-flex-col tw-w-full">
  <div class="secondary-filters tw-flex tw-w-full flex-wrap">
    <div class="name-search form-inline fs-mask">
      <twng-name-filter class="tw-flex-grow" placeholder="Candidate Name" [initialQuery]="currentCandidateNameQuery"
        [updatedQuery]="updatedQuery" (searchedQueryChange)="updateCandidateSearch($event)"></twng-name-filter>
    </div>

    <div class="name-search form-inline fs-mask">
      <twng-candidate-tag-filters class="tw-flex-grow" *ngIf="appConfig.showFilterByAnyTag()" [initialTags]="filters.matchingTagFilters"
        (filtersChange)="updateAnyTagFilter($event)">
      </twng-candidate-tag-filters>
    </div>

    <div class="name-search form-inline fs-mask">
      <twng-custom-fields-filters class="tw-flex-grow" placeholder="Candidate Custom Fields" customFieldType="candidate"
        (filterChange)="updateCustomFieldFilter($event)"
        [selectedCustomFieldFilterOption]="filters.candidateCustomFields">
      </twng-custom-fields-filters>
    </div>

    <div class="name-search form-inline fs-mask">
      <twng-recruiter-filter class="tw-flex-grow" [selectedRecruitersIds]="filters.recruiters"
        (filterChange)="recruitersFilterChanged($event)"></twng-recruiter-filter>
    </div>
    <div class="name-search form-inline fs-mask credited-to-filter">
      <twng-credited-to-filter class="tw-flex-grow" [selectedCreditedTosIds]="filters.creditedTo"
        (filterChange)="creditedToFilterChanged($event)"></twng-credited-to-filter>
    </div>
  </div>

  <div class="filter-blocks tw-pt-2">
    <div class="filter-block star-filter"
      *ngIf="isSpecialFilterEnabled('my-candidates') || isSpecialFilterEnabled('starred')">
      <h6 class="filter-block-label">Special</h6>
      <div class="filter-block-icons">
        <span *ngIf="isSpecialFilterEnabled('my-candidates')" class="fa-stack"
          [class.selected]="filters.myCandidatesOnly" (click)="toggleMyCandidatesOnly()">
          <i class="fas fa-square fa-stack-2x"></i>
          <i class="fas fa-user-circle fa-stack-1x" ngbTooltip="Filter by My Candidates"></i>
        </span>

        <span *ngIf="isSpecialFilterEnabled('starred')" class="fa-stack" [class.selected]="filters.starredOnly"
          (click)="toggleStarred()">
          <i class="fas fa-square fa-stack-2x"></i>
          <i class="fas fa-star fa-stack-1x" ngbTooltip="Filter by Starred"></i>
        </span>
      </div>
    </div>

    <div class="filter-block" *ngIf="appConfig.enableApplicationRatings()">
      <h6 class="filter-block-label">Rating</h6>
      <div class="filter-block-icons">
        <twng-rating-number *ngFor="let ratingNumber of ratingNumbers" [ratingNumber]="ratingNumber" setRatingFilter
          [ratingFilters]="filters.rating" [tooltipText]="'Filter by Rating ' + ratingNumber"
          [selected]="filters.rating.includes(ratingNumber)">
        </twng-rating-number>
      </div>
    </div>

    <ng-container *ngIf="tagMappings$ | async as tagMappings">
      <div *ngIf="tagMappings?.length > 0" class="filter-block tag-mapping-filters">
        <h6 class="filter-block-label">Tags</h6>
        <div class="filter-block-icons">
          <span *ngFor="let tm of tagMappings" class="fa-stack" [class.selected]="filters.matchingTagFilters[tm.tag]"
            (click)="toggleTagFilter(tm)">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-{{ tm.icon }} fa-stack-1x" ngbTooltip="Filter by {{ tm.tag }}"></i>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="sourceTypeIconMappings$ | async as sourceTypeIconMappings">
      <div *ngIf="!isEmpty(sourceTypeIconMappings)" class="filter-block source-filters">
        <h6 class="filter-block-label">Source</h6>
        <div class="filter-block-icons">
          <span *ngFor="let sourceTypeIcon of sourceTypeIconMappings | keyvalue" class="fa-stack" [class.selected]="
                    filters.matchingSourceFilter.includes(sourceTypeIcon.key)
                  " (click)="setSourceFilter(sourceTypeIcon.key)">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-{{ sourceTypeIcon.value }} fa-stack-1x"
              ngbTooltip="Filter by {{ sourceTypeIcon.key }}"></i>
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="filter-block source-filters">
        <h6 class="filter-block-label">Interviews</h6>
        <div class="filter-block-icons">
          <span class="fa-stack" [class.selected]="filters.showInterviewScheduled" (click)="toggleInterviewScheduled()">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-calendar fa-stack-1x" ngbTooltip="Interview scheduled"></i>
          </span>

          <span class="fa-stack" [class.selected]="filters.showInterviewCompleted" (click)="toggleInterviewCompleted()">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-calendar-check fa-stack-1x" ngbTooltip="Interview completed"></i>
          </span>

          <span class="fa-stack" [class.selected]="filters.showNoInterview" (click)="toggleNoInterview()">
            <i class="fas fa-square fa-stack-2x"></i>
            <i class="fas fa-calendar-times fa-stack-1x" ngbTooltip="No Interview"></i>
          </span>
        </div>
      </div>
    </ng-container>

    <div class="filter-block activity-filters">
      <h6 class="filter-block-label">Activity</h6>
      <div class="filter-block-icons">
        <span *ngFor="let activityLevel of activityLevels" (click)="toggleActivityFilter(activityLevel)"
          class="fa-stack">
          <i class="fas fa-square fa-stack-2x activity-filter-{{
                    activityLevel
                  }}" ngbTooltip="Filter by Activity: {{ activityLevel | titlecase }}
                    ({{ getTooltipTextForActivity(activityLevel, store) }})"></i>
          <i *ngIf="filters.matchingActivityLevel?.includes(activityLevel)" class="fas fa-check fa-stack-1x"></i>
        </span>
      </div>
    </div>
  </div>
</div>
