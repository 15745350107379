import { Observable, Subscription, combineLatest } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'

import { ToastrService } from 'ngx-toastr'

import { ActivatedRoute, Router } from '@angular/router'
import {
  AfterViewChecked,
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core'
import {
  NgbModal, NgbModalOptions, NgbNavChangeEvent
} from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'

import * as fromWall from '../reducers'
import {
  ALL_JOBS_TAB_ID,
  CANDIDATES_BY_STAGE_TAB_ID, CANDIDATES_BY_STAGE_URL_NAME, WallSummaryMode, anyFiltersSet as anyCandidateFiltersSet
} from '../reducers/layout.reducer'
import { Account } from '../models/account'
import { AppConfigService } from '../services/app-config.service'
import { DeleteTab } from '../actions/tabs.actions'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { JOBS_PER_PAGE, WallApiService } from '../services/wall-api.service'
import { Location } from '@angular/common';
import { SegmentService } from '../../core/services/segment.service'
import { SiteNotificationComponent } from '../../shared/components/site-notification/site-notification.component'
import { SlackModalService } from '../../shared/services/slack-modal.service'
import { SortJobsModalComponent } from './sort-job/sort-jobs-modal.component'
import { SortTabsComponent } from '../../shared/components/sort-tabs/sort-tabs.component'
import { SortingOptions } from '../models/sorting-options'
import { Tab } from '../models/tab'
import { TabFormComponent } from './tab-form.component'
import { TabShareComponent } from '../../shared/components/tab-share.component'
import { UpdateAllJobsSortingOptions, UpdateSelectedTab } from '../actions/layout.actions'
import { User } from '../models/user'
import { WallDataPaginatedAllJobsFilter, WallDataPaginatedTabFilter } from '../actions/wall.actions'
import { WallPdfService } from '../services/wall-pdf.service'
import { initWallIfNecessary, loadAllJobsOnWallAndWait,
  loadJobStatusesIfPossible
} from '../../shared/utils/store.utils'
import {
  selectAccount, selectUser, selectWallLoadCompleted
} from '../../reducers'

@Component({
  selector: 'twng-wall',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss'],
})
export class WallComponent implements OnInit, OnDestroy, AfterViewChecked {
  ADD_TAB_ID = 'add_tab'
  SORT_TAB_ID = 'sort_tab'
  CANDIDATES_BY_STAGE_STR = (CANDIDATES_BY_STAGE_TAB_ID).toString()
  ALL_JOBS_ID_STR = (ALL_JOBS_TAB_ID).toString()

  user: User
  userSub = new Subscription()
  account: Account
  accountSub: Subscription
  tabsSub: Subscription
  tabs: Tab[]

  wallLoaded$: Observable<boolean>

  allJobsSortingOptions$: Observable<SortingOptions>

  activeTabId$: Observable<string>
  activeTabId: string
  activeRouteSubs: Subscription

  atsName: string

  numVisibleJobs: number | undefined
  allJobsFilter$: Observable<WallDataPaginatedAllJobsFilter>
  currentTabFilter$: Observable<WallDataPaginatedTabFilter>

  pdfOverlayText: string

  canReorderJobs$: Observable<boolean>

  wallSummaryModeTypes = WallSummaryMode
  wallSummaryMode: WallSummaryMode

  @ViewChild('tabsContainer')
    tabsContainer: ElementRef<HTMLDivElement>

  tabsHeight: number

  urlSafe: SafeResourceUrl
  appRoot = document.getElementsByClassName('app-root')[0]
  ngContainer = document.getElementsByClassName('ng-container')[0]
  iframeSubs = new Subscription()
  isLegacyWall: boolean

  constructor(
    private store: Store<fromWall.State>,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    public appConfig: AppConfigService,
    private segmentService: SegmentService,
    private wallPdfService: WallPdfService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private wallApi: WallApiService,
    private slackModal: SlackModalService,
    private location: Location,
    private renderer: Renderer2,
    public sanitizer: DomSanitizer
  ) { }

  tabModalOptions: NgbModalOptions = {
    backdrop: 'static',
  }

  ngOnInit() {
    this.isLegacyWall = this.route.snapshot.data?.isLegacyWall

    this.accountSub = this.store.select(selectAccount).subscribe(account => {
      this.account = account
    })

    this.userSub.add(this.store.select(selectUser).subscribe(user => {
      this.user = user

      if (this.useAllegroWall(user)) {
        window.addEventListener('message', this.onMessageListener)

        this.manageUrl()
        this.renderer.addClass(document.documentElement, 'tw-h-full')
        this.renderer.addClass(document.documentElement, 'hide-scrollbar')
        this.renderer.addClass(document.body, 'tw-h-full')
        this.renderer.addClass(document.body, 'hide-scrollbar')

        if (this.appRoot && this.ngContainer) {
          this.renderer.addClass(this.appRoot, 'tw-h-full')
          this.renderer.addClass(this.ngContainer, 'tw-h-full')
        }
      } else {
        this.loadLegacyWall()
      }

      const siteNotifications = this.user.site_notifications.filter(sn => sn.location === "wall")
      if (siteNotifications?.length) {
        const newTabModalRef = this.modalService.open(
          SiteNotificationComponent,
          { modalDialogClass: '!tw-w-1/2', ...this.tabModalOptions },
        )
        newTabModalRef.componentInstance.siteNotification = siteNotifications[0]
        newTabModalRef.componentInstance.user = user
      }
    }))
  }

  loadLegacyWall() {
    this.wallLoaded$ = this.store.select(selectWallLoadCompleted)

    this.activeTabId$ = this.store.select(fromWall.selectActiveTabId)
      .pipe(
        filter(tabId => tabId !== null),
        map((tabId: number) => (tabId).toString()),
        tap(tabId => this.activeTabId = tabId)
      )

    const tabsSelector = this.store.select(fromWall.selectSortedWallTabs)
    this.tabsSub = tabsSelector.subscribe(tabs => {
      this.tabs = tabs
    })

    // wait until we know we have a value for `tabs`
    this.activeRouteSubs = combineLatest([this.route.paramMap, tabsSelector, this.wallLoaded$])
      .subscribe(([params, tabs, wallLoaded]) => {
        if (!wallLoaded) {
          return
        }
        const tabParamId = params.get('tabId')

        // TODO: test when there are no tabs in DB
        let tabId: number | undefined
        if (tabParamId === 'all-jobs') {
          tabId = +this.ALL_JOBS_ID_STR
        } else if (tabParamId === CANDIDATES_BY_STAGE_URL_NAME) {
          tabId = +this.CANDIDATES_BY_STAGE_STR
        } else {
          if (tabParamId) {
            tabId = +tabParamId
            // Force load all jobs in demo account
          } else if (tabs && tabs[0] && !window.twng_demo) {
            tabId = tabs[0].id
          } else {
            tabId = +this.ALL_JOBS_ID_STR
          }
        }

        this.store.dispatch(
          new UpdateSelectedTab({ tabId }),
        )
      })

    this.atsName = this.appConfig.atsName()

    this.allJobsSortingOptions$ = this.store.select(fromWall.selectAllJobsSortingOptions)

    this.ensureWallIsLoaded()
    loadJobStatusesIfPossible(this.store, this.appConfig)

    this.allJobsFilter$ = this.wallApi.allJobsFilters$
    this.currentTabFilter$ = this.wallApi.getTabJobFilters$(this.store.select(fromWall.selectActiveTab))

    this.canReorderJobs$ = this.store.select(fromWall.selectCandidateFilters).pipe(
      // TODO: we should show this as part of the sort by dropdown when we have few enough jobs
      map(candidateFilters => !anyCandidateFiltersSet(candidateFilters))
    )

    this.userSub.add(this.store.select(fromWall.selectWallSummaryMode).subscribe(mode => {
      this.wallSummaryMode = mode
      this.cd.markForCheck()
    }))
  }

  ngAfterViewChecked(): void {
    const previousHeight = this.tabsHeight
    if (this.tabsContainer) {
      this.tabsHeight = this.tabsContainer.nativeElement.getBoundingClientRect().bottom
      if (previousHeight !== this.tabsHeight && this.wallSummaryMode === WallSummaryMode.CalendarFullscreen) {
        this.cd.markForCheck()
      }
    } else {
      delete this.tabsHeight
    }
  }

  singlePageOfJobs(numVisibleJobs: number): boolean {
    return 0 < numVisibleJobs && numVisibleJobs <= JOBS_PER_PAGE
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe()
    this.accountSub.unsubscribe()

    this.iframeSubs.unsubscribe()
    if (this.useAllegroWall(this.user)) {
      window.removeEventListener('message', this.onMessageListener)

      this.renderer.removeClass(document.documentElement, 'tw-h-full')
      this.renderer.removeClass(document.documentElement, 'hide-scrollbar')
      this.renderer.removeClass(document.body, 'tw-h-full')
      this.renderer.removeClass(document.body, 'hide-scrollbar')

      if (this.appRoot && this.ngContainer) {
        this.renderer.removeClass(this.appRoot, 'tw-h-full')
        this.renderer.removeClass(this.ngContainer, 'tw-h-full')
      }
    } else {
      this.tabsSub.unsubscribe()
      this.activeRouteSubs.unsubscribe()
    }
  }

  manageUrl() {
    if (this.useAllegroWall(this.user)) {
      let tabsUrl = ''
      if (this.router.url.includes('tabs')) {

        tabsUrl = `/tabs${this.router.url.split('tabs')[1]}`
      }
      this.iframeSubs.add(this.route.url.subscribe(url => {
        if (url[0] && url[0].path.includes('wall')) {
          this.location.replaceState(`/wall${tabsUrl}`)
        }
      }))

      const url = `//${window.sobrio_host_name}/${this.account.allegro_slug}/wall${tabsUrl}`
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }
  }

  onMessageListener = (event) => {
    const { data, origin } = event

    if (origin.includes(window.sobrio_host_name)) {
      const parsedData = JSON.parse(data)

      if (parsedData.wallTabId) {
        this.location.replaceState(`/wall/tabs/${parsedData.wallTabId}`)
      }
      if (parsedData.wallTabId === null) {
        this.location.replaceState(`/wall`)
      }
    }
  }

  updateSelectedTab($changeTabEvent: NgbNavChangeEvent): void {
    const wallUrl = this.isLegacyWall ? 'legacy_wall' : 'wall'
    if ($changeTabEvent.nextId === this.ADD_TAB_ID) {
      this.openNewTabModal()
      $changeTabEvent.preventDefault()
    } else if ($changeTabEvent.nextId === this.SORT_TAB_ID) {
      this.openSortTabsModal()
      $changeTabEvent.preventDefault()
    } else if ($changeTabEvent.nextId === this.ALL_JOBS_ID_STR) {
      this.segmentService.track('Select All Jobs tab')
      this.router.navigateByUrl(`/${wallUrl}/tabs/all-jobs`)
    } else if ($changeTabEvent.nextId === this.CANDIDATES_BY_STAGE_STR) {
      this.segmentService.track('Select Candidates By Stage')
      this.router.navigateByUrl(`/${wallUrl}/tabs/${CANDIDATES_BY_STAGE_URL_NAME}`)
    } else {
      this.router.navigateByUrl(`/${wallUrl}/tabs/${$changeTabEvent.nextId}`)
    }
  }

  ensureWallIsLoaded() {
    initWallIfNecessary(this.store)
  }

  userCanEditTabs(): boolean {
    return !!this.user
  }

  editTabModal(tab: Tab): void {
    const editTabModalRef = this.modalService.open(
      TabFormComponent,
      this.tabModalOptions,
    )
    const tabFormComponent: TabFormComponent = editTabModalRef
      .componentInstance
    tabFormComponent.setTab(tab)

    this.segmentService.track('Edit Tab (open modal)')
  }

  shareTabModal(): void {
    const newTabModalRef = this.modalService.open(
      TabShareComponent,
      this.tabModalOptions,
    )
    newTabModalRef.componentInstance.tabType = 'wall'
    newTabModalRef.componentInstance.selectedTabId = this.activeTabId
  }

  // TODO: Move this into an effect
  deleteTab(tab: Tab): void {
    const nextTab = this.tabs.find(t => t.id !== tab.id)
    const nextTabId = nextTab ? nextTab.id : ALL_JOBS_TAB_ID
    this.store.dispatch(
      new UpdateSelectedTab({
        tabId: nextTabId,
      }),
    )
    this.store.dispatch(
      new DeleteTab({
        tab,
      }),
    )
  }

  openNewTabModal(): void {
    this.segmentService.track('New Tab (open modal)')

    this.modalService.open(
      TabFormComponent,
      this.tabModalOptions,
    )
  }

  openModalForJobs() {
    this.segmentService.track('Reorder Wall Tab Jobs (open modal)')
    this.modalService.open(SortJobsModalComponent)
  }

  openSortTabsModal(): void {
    this.segmentService.track('Sort Wall Tabs (open modal)')

    const sortTabsModalRef = this.modalService.open(SortTabsComponent)
    sortTabsModalRef.componentInstance.tabs$ = this.store.select(fromWall.selectSortedWallTabs)
    sortTabsModalRef.componentInstance.tabsType = 'wall'
    sortTabsModalRef.result.then(
      () => {
        this.cd.markForCheck()
      },
      () => { },
    )
  }

  sortingOptionsChanged(sortingOption: SortingOptions) {
    this.store.dispatch(new UpdateAllJobsSortingOptions(sortingOption))
  }

  /**
   * Export to pdf the current tab
   */
  async exportTabToPDF(tab: Tab) {
    const waiter = loadAllJobsOnWallAndWait(this.store)
    this.pdfOverlayText = "Downloading job data ..."
    this.cd.detectChanges()
    this.segmentService.track("Export Wall to PDF")
    await waiter
    this.pdfOverlayText = "Preparing your PDF ..."
    this.cd.detectChanges()
    await this.wallPdfService.exportToPDF('', { tabName: tab.name })
    // this will hide pdf overlay
    delete this.pdfOverlayText
    this.cd.detectChanges()
  }

  getClipboardLink(tabId: string | number, token: string) {
    return window.location.host + '/wall-shared/' + tabId + '/' + token
  }

  copiedNotification() {
    this.toastr.success(`Copied to clipboard`)
    this.segmentService.track('Copy Wall Tab link to clipboard')
  }

  tabsHeightChanged(newHeight: number) {
    this.tabsHeight = newHeight
  }

  openSendToSlackTab(tab: Tab) {
    this.slackModal.openSendToSlackTab(
      `Checkout my TalentWall Wall: ${this.getClipboardLink(tab.id, tab.sharable_token)}`
    )
  }

  useAllegroWall(user) {
    return user.enable_allegro_wall && !this.isLegacyWall
  }
}
