import { ActivatedRoute } from '@angular/router'
import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobViewMode } from '../../reducers/layout.reducer'
import { Store } from '@ngrx/store'
import { Subscription, combineLatest } from 'rxjs'
import { UpdateJobViewMode } from '../../actions/layout.actions'
import { WallApiService } from '../../services/wall-api.service'
import { WallDataPaginatedAllJobsFilter } from '../../actions/wall.actions'

@Component({
  selector: 'twng-wall-shared-single-job',
  templateUrl: './wall-shared-single-job.component.html',
  styleUrls: ['./wall-shared-single-job.component.css']
})
export class WallSharedSingleJobComponent implements OnInit, OnDestroy {

  singleJobFilter: WallDataPaginatedAllJobsFilter
  // if we should show 'no permission' message
  noPermission: boolean
  private sub = new Subscription()

  constructor(
    private wallApi: WallApiService,
    private route: ActivatedRoute,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.route.params.subscribe(params => {
        this.singleJobFilter = this.wallApi.getJobFilterForSingleJob(params.jobId)
      })
    )
    this.sub.add(
      combineLatest([this.route.queryParams, this.route.params]).subscribe(([query, params]) => {
        const queryViewMode: string | undefined = query.viewMode
        const viewMode = JobViewMode[queryViewMode]
        if (viewMode !== null) {
          this.store.dispatch(new UpdateJobViewMode({ jobId: params.jobId, viewMode }))
          console.log("Update view mode: ", viewMode)
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  newTotalJobs(newCount: number) {
    this.noPermission = newCount === 0
  }

}
