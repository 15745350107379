
import { Component, Input, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'

import * as fromWall from '../../reducers'
import { Job } from '../../models/job'
import { Tab } from '../../models/tab'
import { cloneDeep } from 'lodash-es'

@Component({
  selector: 'twng-sort-job-item',
  styleUrls: ['./sort-job.component.scss'],
  templateUrl: './sort-job.component.html',
})
export class SortJobComponent implements OnInit {
  @Input()
    job: Job

  @Input()
    index: number

  dragModel: {
    job: Job,
    tab: Tab
  }

  tab: Tab

  constructor(
    private store: Store<fromWall.State>,
  ) { }

  ngOnInit() {
    this.store.pipe(select(fromWall.selectActiveTab)).subscribe(tab => {
      this.tab = tab
      this.dragModel = {job: cloneDeep(this.job), tab: cloneDeep(this.tab)}
    })
  }
}
