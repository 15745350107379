<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">Drag jobs to sort</twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <div *ngIf="(jobs$ | async) as jobs; else spinner" style="max-height: 60vh; overflow: auto" appDroppable
      (dropping)="reorderJobs($event, jobs)">
      <twng-sort-job-item *ngFor="let job of jobs; index as i" [job]="job" [index]="i"></twng-sort-job-item>
    </div>
  </twng-modal-body>
  <twng-modal-footer>
    <button type="submit" (click)="closeModal()"
      class="button--primary button--large tw-mb-2 tw-mr-2 tw-flex tw-items-center tw-rounded-md tw-box-border tw-not-italic tw-h-fit tw-text-white tw-bg-cq-primary-600 tw-text-sm tw-p-3">
      Done
    </button>
  </twng-modal-footer>
</twng-modal>
<ng-template #spinner><twng-spinner></twng-spinner></ng-template>