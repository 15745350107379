<div class="h-100 position-relative d-flex" [class.full-screen]="isFullscreen" [class.px-6]="isFullscreen"
  style="z-index: 1; background-color: white" dimensionMonitor (heightChanged)="handleHeightChanged($event)">
  <div *ngIf="isFullscreen" class="detail-pane tw-pr-3 tw-pl-3 widget"
    [ngStyle]="!selectedEvents && {'background-color': 'white'}">
    <ng-container *ngIf="selectedEvents">
      <div class="details-header w-100 tw-pb-3">
        <span>Details</span>
        <i class="fas fa-times" (click)="emptySelectedEvents()"></i>
      </div>
      <div *ngFor="let event of selectedEvents">
        <div class="times">
          <i class="fas fa-circle"></i>{{ event.start_time | date: 'longDate' }}
        </div>
        <div class="accordion-container">
          <ngb-accordion #acc="ngbAccordion" (panelChange)="changeSelectedId(event.id)" [activeIds]="selectedId" class="details-accordion">
            <ngb-panel id="stage-{{ event.id }}">

              <ng-template ngbPanelTitle>
                <div *ngIf="selectedEvent.id === event.id; else elseBlock" class="d-flex justify-content-between w-100">
                  <div class="details-event">
                    <span class="job">{{ event.job }}</span>
                    <span class="stage">{{ event.name }}</span>
                    <span class="candidate tw-pt-2">{{ event.candidate }}</span>
                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="d-flex justify-content-between w-100">
                    <div class="details-event flex-row align-items-center">
                      <i *ngIf="isPast(event.end_time)" class="fas fa-calendar-check tw-pr-2" style="color: #9E9E9E;"></i>
                      <i *ngIf="!isPast(event.end_time)" class="fas fa-calendar tw-pr-2" style="color: #9E9E9E;"></i>
                      <span class="stage">{{ event.name }}</span>
                    </div>
                  </div>
                </ng-template>
              </ng-template>

              <ng-template ngbPanelContent>
                <ng-container *ngIf="isPast(event.end_time) && getResponses(event) as responses">
                  <div *ngFor="let interviewer of responses.responded" class="interviewers">
                    <span>
                      {{ responses.responded.length }} submitted scorecard{{ responses.responded.length === 1 ? "" : "s" }}
                    </span>
                    <p>
                      <span [innerHtml]="getInterviewersScoresIcons(interviewer)"></span>
                      <span>{{ interviewer.name}}</span>
                    </p>
                  </div>
                  <div *ngIf="responses.notResponded.length" class="interviewers">
                    <span>
                      {{ responses.notResponded.length }} yet to submit scorecard{{ responses.notResponded.length === 1 ? "" : "s" }}
                    </span>
                    <p>{{ getInterviewersName(responses.notResponded) }}</p>
                  </div>
                </ng-container>

                <ng-container *ngIf="!isPast(event.end_time) && getResponses(event) as responses">
                  <div class="interviewers">
                    <span *ngIf="responses.responded.length">{{ responses.responded.length}} response{{responses.responded.length === 1 ? "" : "s" }}</span>
                  </div>
                  <div *ngFor="let interviewer of responses.responded" class="interviewers">
                    <p>
                      <span [innerHtml]="getInterviewersResponseIcons(interviewer)"></span>
                      <span>{{ interviewer.name }}</span>
                    </p>
                  </div>
                  <div *ngIf="responses.notResponded.length" class="interviewers">
                    <span>{{ responses.notResponded.length }} not responded</span>
                    <p>{{ getInterviewersName(responses.notResponded) }}</p>
                  </div>
                </ng-container>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="widget h-100 full-callendar-container" style="flex-grow: 2;" (click)="setFullscreen(true, $event)">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>

  <div id="loading" *ngIf="isLoading">
    <i class="fas fa-sync fa-3x fa-spin"></i>
  </div>
</div>
