<div class='hiring-management-container' *ngIf="!user.enable_allegro_exec_dash">
  <ul ngbNav #nav="ngbNav" class="nav nav-tabs app__tabs" [(activeId)]="active">
    <li ngbNavItem="executive-dashboard">
      <a ngbNavLink routerLink="/executive-tools/executive-dashboard" class="fs-unmask">Executive Dashboard</a>
    </li>

    <li ngbNavItem="capacity-planning">
      <a ngbNavLink routerLink="/executive-tools/capacity-planning" class="fs-unmask">Capacity Planning</a>
    </li>
    <li *ngIf="user?.can_view_eeoc" ngbNavItem="funnel-segmentation">
      <a ngbNavLink routerLink="/executive-tools/funnel-segmentation" class="fs-unmask" data-intercom-target="Funnel Segmentation">Funnel Segmentation</a>
    </li>
  </ul>

  <router-outlet></router-outlet>
</div>

<div class="tw-flex tw-h-[calc(100%-4rem)]" *ngIf="user.enable_allegro_exec_dash">
    <iframe class="iframe" [src]="urlSafe" frameborder="0" scrolling="yes" width="100%" 
    allow="clipboard-read https://sobrio.loco.crosschq.dev https://sobrio.app.talentwall.io; clipboard-write https://sobrio.app.talentwall.io https://sobrio.loco.crosschq.dev"
    style="flex-grow: 1; border: none; margin: 0; padding: 0;"> 
    </iframe>
</div>
