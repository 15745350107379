<div class="collapsed-chart" *ngIf="chart">
  <div class="title tw-pt-2 tw-pb-1">{{chart.name}}</div>
  <div class="boxes">
    <div *ngFor="let row of chart.data.data"
       class="box tw-mr-2 tw-px-2 text-center align-middle"
       [ngStyle]="{'background-color': lightColor(row)}"
       [ngbTooltip]="row.tooltip || row.name">
      <span class="dot align-middle" 
        [ngStyle]="{'background-color': color(row)}"></span> {{row.value}}
    </div>
  </div>
</div>