import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PortalLocation, PortalTrackerService, isLocationValid } from '../../services/portal-tracker.service'
import { Subscription, timer } from 'rxjs'
import ResizeObserver from 'resize-observer-polyfill';

// This component monitors changes in its position/size and broadcasts it to
// PortalTrackerService. Portals can then be used to render components in that
// position/size, but may not necessarily be children of this component.
// NOTE:
// If there is no portal with this name, the children will still be alive with
// display: none. This is done so that we don't re-create children. To destroy
// children, you should remove this component as well (for example with *ngIf)
@Component({
  selector: 'twng-portal',
  template: '<div id="portalLoading"><i class="fas fa-sync fa-3x fa-spin"></i></div>',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
    portalName: string

  private sub = new Subscription()
  private portalId: number
  private resizeObserver: ResizeObserver

  constructor(
    private portalTracker: PortalTrackerService,
    private elementRef: ElementRef<HTMLDivElement>,
  ) { }

  ngOnInit(): void {
    if (!this.portalName) {
      throw new Error("portalName property is required for PortalComponent")
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    this.portalTracker.removePortal(this.portalName, this.portalId)
  }

  ngAfterViewInit(): void {
    this.portalId = this.portalTracker.addPortal(this.portalName, this.getPortalLocation())
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => this.updatePortalLocation())
      this.resizeObserver.observe(this.elementRef.nativeElement)
    } else {
      console.warn("Using fallback to timer for portal position tracking")
      this.sub.add(timer(0, 500).subscribe(() => this.updatePortalLocation()))
    }
  }

  private updatePortalLocation() {
    const location = this.getPortalLocation()
    if (isLocationValid(location)) {
      this.portalTracker.updatePortal(this.portalName, this.portalId, location)
    }
  }

  private getPortalLocation(): PortalLocation {
    const el = this.elementRef.nativeElement
    const rect = el.getBoundingClientRect()
    return {
      height: rect.height,
      width: rect.width,
      x: rect.x + window.scrollX,
      y: rect.y + window.scrollY,
    }
  }
}
