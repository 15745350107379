import { CalendarInterviewer, InterviewCalendarEvent } from '../../models/interview'
import { CalendarOptions, EventInput, ToolbarInput } from '@fullcalendar/core'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component,
  OnDestroy, OnInit, SecurityContext, ViewChild
} from '@angular/core'
import { DomSanitizer, SafeValue } from '@angular/platform-browser'
import { FullCalendarComponent } from '@fullcalendar/angular'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { UpdateCalendarWeek, UpdateWallSummaryMode } from '../../actions/layout.actions'
import { WallApiService } from '../../services/wall-api.service'
import { WallDataPaginatedAllJobsFilter, WallDataPaginatedTabFilter } from '../../actions/wall.actions'
import { WallSummaryMode } from '../../reducers/layout.reducer'
import { cloneDeep, sortBy } from 'lodash-es'
import { finalize, map } from 'rxjs/operators'
import { format } from 'date-fns'
import { selectCalendarWeek, selectWallSummaryMode } from '../../reducers'
import { sleep } from '../../../shared/utils/sleep'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'

interface CalendarEventExtendedProps {
  jobData?: {
    job: string,
    candidate: string,
    count: number,
    events: InterviewCalendarEvent[]
  }
  // used for rows that display only time
  isJobCard: boolean
}

@Component({
  selector: 'twng-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, OnDestroy {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, bootstrapPlugin, listPlugin, timeGridPlugin],
    customButtons: {
      prev: {
        click: () => {
          this.selectedEvent = null
          this.calendarComponent.getApi().prev()
        },
      },
      next: {
        click: () => {
          this.selectedEvent = null
          this.calendarComponent.getApi().next()
        },
      },
      fullscreen: {
        click: () => this.setFullscreen(true),
        bootstrapFontAwesome: 'expand',
      },
      exitFullscreen: {
        click: () => {
          this.setCalendarWeek()
          this.setFullscreen(false)
        },
        bootstrapFontAwesome: 'compress',
      }
    },
    dayHeaderClassNames: 'text-left day-header',
    initialView: 'dayGridWeek',
    weekends: false,
    headerToolbar: {
      start: 'title',
      center: '',
      end: '',
    },
    titleFormat: { month: 'long', day:'numeric', year:'numeric' },
    dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true },
    themeSystem: 'bootstrap',
    eventSources: [{
      events: ({ start, end }, success, failure) => {
        this.refreshData(start, end).then(success).catch(failure)
      }
    }],
    eventClick: this.handleEventClick.bind(this),
    navLinkDayClick: this.handleDateClick.bind(this),
    moreLinkClick: this.handleDateClick.bind(this),
    eventContent: this.handleEventContent.bind(this),
    eventOrder: 'start_time',
    loading: (isLoading) => {
      this.setLoading(isLoading)
    }
  }

  private currentFilterInfo: WallDataPaginatedTabFilter | WallDataPaginatedAllJobsFilter

  private sub = new Subscription()
  private dataFetchSubscription: Subscription
  isFullscreen = false
  isCollapsed = false
  isLoading = true
  selectedEvents: InterviewCalendarEvent[]
  selectedEvent: InterviewCalendarEvent
  // this will be selected AFTER the next refreshData is called
  nextSelectedEvents: InterviewCalendarEvent[]
  selectedId: string

  classSelectedEvent = 'selected-event'
  classSelectedApplication = 'selected-application'

  constructor(
    private wallApi: WallApiService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    document.getElementById('portalLoading').style.display = 'none'

    // wait until calendar is fully available
    do {
      await sleep(100)
    } while (!this.calendarComponent?.getApi())
    // re-render calendar. Fixes some layout issues
    this.calendarComponent.getApi().render()
    // any time filters are changed, we need to tell calendar to refetch events
    this.sub.add(this.wallApi.currentlyVisibleRelevantFilters$.subscribe(info => {
      this.currentFilterInfo = info
      this.calendarComponent.getApi().refetchEvents()
    }))
    // any time fullscreen is pressed, we need to change fullscreen icons
    this.sub.add(this.store.select(selectWallSummaryMode).subscribe(mode => {
      this.isFullscreen = mode === WallSummaryMode.CalendarFullscreen
      this.isCollapsed = mode === WallSummaryMode.Collapsed
      this.refreshEndButtons()
      this.cd.markForCheck()
    }))
    this.setCalendarGoToDate()
  }

  handleDateClick() {
    console.log("handleDateClick")
  }

  private s(str: string | SafeValue) {
    return this.sanitizer.sanitize(SecurityContext.HTML, str)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEventContent(arg: { event: any; timeText: string | SafeValue, view: any }) {
    const event = arg.event
    const extendedProps: CalendarEventExtendedProps = event.extendedProps
    if (extendedProps.isJobCard) {
      // this is job card
      return {
        html: `
          <div class="event-data ${arg.view.type === 'listWeek' ? 'list-view' : ''}">
            <span class="job ${this.getWidthClass(arg.view.type)}">${this.s(extendedProps.jobData.job)}</span>
            <span class="stage ${this.getWidthClass(arg.view.type)}"><i class="fas fa-flag ${this.isFullscreen ? '' : 'hide '}"></i>${this.s(event.title)}</span>
            <span class="candidate ${this.isFullscreen ? '' : 'hide '} ${this.getWidthClass(arg.view.type)}">
              <i class="fas fa-user"></i>${this.s(extendedProps.jobData.candidate)}
            </span>
            ${this.getScorecardsOrAcceptance(extendedProps.jobData)}
          </div>
      ` }
    } else {
      // this event only displays time (and events are below it)
      return {
        html: `
          <div class="time">${event.title}</div>
        `
      }
    }
  }

  getWidthClass(type) {
    if (type === 'listWeek') {
      return this.isFullscreen ? 'width-25' : 'width-50'
    }
    return ''
  }

  handleEventClick(info) {
    const extendedProps: CalendarEventExtendedProps = info.event.extendedProps
    // there are cases where we dont have job data ... for example when we
    // click on "time group" event (the one that only displays time)
    if (extendedProps.jobData) {
      this.removeCurrentClasses()
      this.selectedEvents = []
      this.selectedEvent = null

      info.event.setProp('classNames', [...info.event.classNames, this.classSelectedEvent])

      this.selectedEvent = extendedProps.jobData.events[0]
      this.getJobApplicationInterviews(this.selectedEvent.job_application_id)
      this.selectedId = `stage-${this.selectedEvent.id}`

      if (!this.isFullscreen) {
        this.nextSelectedEvents = extendedProps.jobData.events
        this.setFullscreen(true)
      }
      this.cd.markForCheck()
    }
  }

  // whenever height is changed, notify calendar about it
  handleHeightChanged(newHeight: number) {
    this.calendarOptions.height = newHeight
  }

  // Convert array of interviews to array of appropriate events for
  // fullcalendar. This also appends "time group" events, which only display
  // time. Below those time groups are all interviews that belong to that time.
  private convertInterviewsToEvents(interviews: InterviewCalendarEvent[]) {
    const sorted = sortBy(interviews, i => [new Date(i.start_time), new Date(i.end_time)])
    const ret = []
    let previousStartDate: Date = null
    let previousEndDate: Date = null
    sorted.forEach(interview => {
      const st = new Date(interview.start_time)
      const et = new Date(interview.end_time)
      let addClass = ''
      // check if we need to make a new "time group" event
      if (previousStartDate?.getTime() !== st.getTime() || previousEndDate?.getTime() !== et.getTime()) {
        previousStartDate = st
        previousEndDate = et
        const timeExtendedProps: CalendarEventExtendedProps = {
          isJobCard: false
        }
        // this is how "time group" event looks like
        ret.push({
          date: st,
          end: et,
          title: `${format(st, "hh:mm aaaa")} - ${format(et, "hh:mm aaaa")}`,
          extendedProps: timeExtendedProps,
        })
      } else if (previousStartDate?.getTime() === st.getTime() && previousEndDate?.getTime() === et.getTime()) {
        addClass = 'same-time'
      }
      // insert the event
      const extendedProps: CalendarEventExtendedProps = {
        jobData: {
          job: interview.job,
          candidate: interview.candidate,
          count: interview.count,
          events: interview.subEvents || [interview]
        },
        isJobCard: true,
      }
      ret.push({
        id: interview.id,
        title: interview.name,
        date: interview.start_time,
        end: interview.end_time,
        className: interview.mine ? `featured event ${this.getSelectedClass(interview.id)}`
          : `event ${this.getSelectedClass(interview.id)} ${addClass}`,
        textColor: interview.mine ? '#0369A1' : '#212121',
        extendedProps
      })
    })
    return ret
  }

  private refreshData(start: Date, end: Date): Promise<EventInput[]> {
    if (!this.currentFilterInfo) {
      return Promise.resolve([])
    }
    this.cancelPreviousDataIfPossible()
    this.setLoading(true)
    this.selectedEvents = this.nextSelectedEvents
    this.nextSelectedEvents = null
    return new Promise((resolve, reject) => {
      this.dataFetchSubscription = this.wallApi
        .getUpcomingInterviews(this.currentFilterInfo, start, end)
        .pipe(
          map(interviews => this.convertInterviewsToEvents(interviews)),
          finalize(() => {
            this.setLoading(false)
          }),
        )
        .subscribe(resolve, reject)
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
    this.cancelPreviousDataIfPossible()
  }

  private setLoading(newValue: boolean) {
    this.isLoading = newValue
    this.cd.markForCheck()
  }

  private cancelPreviousDataIfPossible() {
    this.dataFetchSubscription?.unsubscribe()
    delete this.dataFetchSubscription
  }

  setFullscreen(isFullscreen: boolean, event?: Event) {
    if ((event?.target as HTMLButtonElement)?.type === 'button' ||
      (event?.target as HTMLElement)?.classList.value.includes('fa')) {
      return
    }
    const wallSummaryMode = isFullscreen ? WallSummaryMode.CalendarFullscreen : WallSummaryMode.Normal
    this.store.dispatch(new UpdateWallSummaryMode(wallSummaryMode))
  }

  private setCalendarWeek() {
    const currentDate = this.calendarComponent.getApi().getDate()
    this.store.dispatch(new UpdateCalendarWeek(currentDate.toISOString()))
  }

  // refresh buttons that go to end
  private refreshEndButtons() {
    this.calendarOptions = cloneDeep(this.calendarOptions);
    (this.calendarOptions.headerToolbar as ToolbarInput).end =
      'prev,today,next dayGridWeek,dayGridDay,listWeek ' + (this.isFullscreen ? 'exitFullscreen' : 'fullscreen')
    this.cd.detectChanges()
  }

  getInterviewersScoresIcons(interviewers: CalendarInterviewer) {
    if (interviewers.scorecard) {
      const scorecardTypes = {
        definitely_not: '<i class="fas fa-thumbs-down"></i><i class="fas fa-thumbs-down"></i>',
        no: '<i class="fas fa-thumbs-down"></i>',
        no_decision: '<i class="fas fa-question-circle"></i>',
        yes: '<i class="fas fa-thumbs-up"></i>',
        strong_yes: '<i class="fas fa-thumbs-up"></i><i class="fas fa-thumbs-up"></i>'
      }
      return scorecardTypes[interviewers.scorecard.recommendation]
    }

  }

  getInterviewersResponseIcons(interviewers: CalendarInterviewer) {
    const scorecardTypes = {
      declined: '<i class="fas fa-times-circle"></i>',
      tentative: '<i class="fas fa-question-circle"></i>',
      accepted: '<i class="fas fa-check-circle"></i>',
    }
    return scorecardTypes[interviewers.status]
  }


  getScorecardsOrAcceptance(job: CalendarEventExtendedProps["jobData"] ): string {
    const isPastJob = this.isPast(job.events[0].end_time)

    const totalResponses = Object.values(job.events[0].interviewer_responses).reduce((accum, item) => accum + item, 0)
    const totalAcceptedResponses = job.events[0].interviewer_responses.accepted
    const totalSubmitedScorecars = job.events[0].interviewers.filter(i => i.scorecard).length
    const totalScorecars = job.events[0].interviewers.length
    const totalDeclinedResponses = job.events[0].interviewer_responses.declined

    let iconClass = totalResponses === totalAcceptedResponses ? 'fa-check-circle' : 'fa-calendar'
    iconClass = isPastJob ? 'fa-list' : iconClass

    const text =  isPastJob ? 'submitted scorecards' : 'accepted'
    const numberResponses = isPastJob ? `${totalSubmitedScorecars}/${totalScorecars}` :
      `${totalAcceptedResponses}/${totalResponses}`

    let color = '#757575'
    if ((!isPastJob && (totalAcceptedResponses * 100 / totalResponses) > 75) ||
         (isPastJob && (totalSubmitedScorecars * 100 / totalScorecars) > 75)) {
      color = '#00C853'
    } else if (!isPastJob && (totalAcceptedResponses * 100 / totalResponses) > 49) {
      color = '#BFA206'
    } else if ((!isPastJob && (totalDeclinedResponses * 100 / totalResponses) > 25) ||
                (isPastJob && ((totalScorecars - totalSubmitedScorecars) * 100 / totalScorecars) > 25) ||
                (!isPastJob && ((totalResponses - totalAcceptedResponses) * 100 / totalResponses) > 25)
    ) {
      color = '#FF5722'
    }

    return `
      <p class="extra ${this.isFullscreen ? '' : 'hide' }">
        <span style="color: ${color}" class="font-weight-bold">
          <i class="fas ${iconClass}"></i>${numberResponses}
        </span>
        ${text}
        <span class="${!isPastJob && totalDeclinedResponses ? '' : 'hide' }">
          <i class="fas fa-times-circle declined"></i>
          <span class="font-weight-bold">${totalDeclinedResponses}</span> declined
        </span>
      </p>
    `
  }

  emptySelectedEvents() {
    this.removeCurrentClasses()
    this.selectedEvents = null
    this.selectedEvent = null
  }

  getResponses(interview: InterviewCalendarEvent) {
    const events = {
      responded: [],
      notResponded: []
    }

    if (this.isPast(interview.end_time)) {
      interview.interviewers.forEach(i => {
        if (i.scorecard) {
          events.responded.push(i)
        } else {
          events.notResponded.push(i)
        }
      })
    } else {
      interview.interviewers.forEach(i => {
        if (i.status === 'needs_action') {
          events.notResponded.push(i)
        } else {
          events.responded.push(i)
        }
      })
    }

    return events
  }

  isPast(end_time: string | Date): boolean {
    return new Date(end_time) < new Date()
  }

  getInterviewersName(interviewers: CalendarInterviewer[]): string {
    return interviewers.map(i => i.name).join(', ')
  }

  getJobApplicationInterviews(job_application_id) {
    this.sub.add(this.wallApi.getJobApplicationInterviews(job_application_id).subscribe({
      next: (data) => {
        this.selectedEvents = data
        this.setSelectedApplicationClass()

        this.cd.markForCheck()
      }
    }))
  }

  removeCurrentClasses() {
    const currentSelected = this.calendarComponent.getApi().getEventById(this.selectedEvent?.id)
    const currentIndex = currentSelected?.classNames.indexOf(this.classSelectedEvent);
    if (currentIndex > -1) {
      const currentClasses = [...currentSelected.classNames]
      currentClasses.splice(currentIndex, 1);
      currentSelected?.setProp('classNames', currentClasses)
    }

    this.selectedEvents?.forEach(event => {
      const currentEvent = this.calendarComponent.getApi().getEventById(event.id)
      const index = currentEvent?.classNames.indexOf(this.classSelectedApplication);
      if (index > -1) {
        const currentClasses = [...currentEvent.classNames]
        currentClasses.splice(index, 1);
        currentEvent?.setProp('classNames', currentClasses)
      }
    })
  }

  setSelectedApplicationClass() {
    this.selectedEvents?.forEach(event => {
      const ev = this.calendarComponent.getApi().getEventById(event.id)
      if (!ev?.classNames.includes(this.classSelectedEvent)) {
        ev?.setProp('classNames', [...ev.classNames, this.classSelectedApplication])
      }
    })
  }

  getSelectedClass(id) {
    let eventClass = ''
    if (this.selectedEvent?.id === id) {
      eventClass = this.classSelectedEvent
    } else if (this.selectedEvents?.some(ev => ev.id === id)) {
      eventClass = this.classSelectedApplication
    }
    return eventClass
  }

  changeSelectedId(id) {
    this.selectedId = `stage-${id}`
  }

  setCalendarGoToDate() {
    this.sub.add(this.store.select(selectCalendarWeek).subscribe(date => {
      if (date) {
        this.calendarComponent.getApi().gotoDate(date)
      }
    }))
  }
}
