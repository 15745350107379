<twng-modal>
  <twng-modal-header>
    <twng-modal-title slot="header-title">
      Create New Tab in Executive Dashboard
    </twng-modal-title>
  </twng-modal-header>
  <twng-modal-body>
    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="tab">
      <input class="form-control" placeholder="Tab name" [formControlName]="'name'" type="string" autofocus>
      <div class="job-filters">
        <twng-jobs-filters [hideJobName]="true" [currentJobFilters]="tab" (filtersChange)="updateJobFilters($event)"
          jobFilterType="active">
        </twng-jobs-filters>
      </div>
    </form>
  </twng-modal-body>
  <twng-modal-footer>
    <span class="text-danger" *ngIf="isDemo">
      Cannot save configuration in demo mode.
    </span>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="form.disabled || form.invalid || isDemo">
      Save
    </button>
  </twng-modal-footer>
</twng-modal>