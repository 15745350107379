import { Chart } from "../../../dashboard/models/dashboard-chart"
import { indexOf, map, sortBy, toPairs } from "lodash-es"

export const collapseSeries = (chart: Chart, names: string[]): Chart => {
  const counts = {}
  chart.data.data.forEach(({ series: series }) => {
    series.forEach(({ name: name, value: count, label }) => {
      counts[name] = counts[name] || {}
      counts[name].value = (counts[name].value || 0) + count
      counts[name].label = label
    })
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any =
    sortBy(map(toPairs(counts), ([name, value]) => ({ name, ...value as Record<string, unknown> })),
      ({ name: name }) => indexOf(names, name))

  return {
    ...chart,
    data: { ...chart.data, data }
  }
}
