import { ActivatedRoute, Router } from '@angular/router'
import { AppConfigService } from '../../wall/services/app-config.service'
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { DashboardSendChartToCustom, DashboardSendChartToCustomPayload } from '../../dashboard/actions/filters.actions'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { SiteNotificationComponent } from '../../shared/components/site-notification/site-notification.component'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { User } from '../../wall/models/user'
import { selectAccount, selectUser } from '../../reducers'

@Component({
  selector: 'twng-hiring-management',
  templateUrl: './hiring-management.component.html'
})
export class HiringManagementComponent implements OnInit, OnDestroy {
  subs = new Subscription()
  user: User
  active = 'executive-dashboard'
  urlSafe: SafeResourceUrl
  appRoot = document.getElementsByClassName('app-root')[0]
  ngContainer = document.getElementsByClassName('ng-container')[0]

  constructor(public appConfig: AppConfigService,
    public store: Store,
    private router: Router,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private location: Location,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.setActiveTab()

    this.subs.add(this.store.select(selectUser).subscribe(user => {
      if (!user.can_view_exec_dashboard && !user.enable_allegro_exec_dash ||
        !user.enable_allegro_exec_dash && this.router.url.includes('tabs')) {
        this.router.navigate(['executive-tools', 'executive-dashboard', 'not_found'])
      }

      this.user = user
      if (user.enable_allegro_exec_dash) {
        this.manageUrl()
        this.renderer.addClass(document.documentElement, 'tw-h-full')
        this.renderer.addClass(document.documentElement, 'hide-scrollbar')
        this.renderer.addClass(document.body, 'tw-h-full')
        this.renderer.addClass(document.body, 'hide-scrollbar')

        if (this.appRoot && this.ngContainer) {
          this.renderer.addClass(this.appRoot, 'tw-h-full')
          this.renderer.addClass(this.ngContainer, 'tw-h-full')
        }
      }

      const siteNotifications = user.site_notifications.filter(sn => sn.location === "exec_dash")
      if (siteNotifications?.length) {
        const newTabModalRef = this.modalService.open(
          SiteNotificationComponent,
          { modalDialogClass: '!tw-w-1/2' }
        )
        newTabModalRef.componentInstance.siteNotification = siteNotifications[0]
        newTabModalRef.componentInstance.user = user
      }
    }))


    window.addEventListener('message', this.onMessageListener)
  }

  manageUrl() {
    if (this.user.enable_allegro_exec_dash) {
      let tabsUrl = ''
      if (this.router.url.includes('tabs')) {
        tabsUrl = `/tabs${this.router.url.split('tabs')[1]}`
      }
      this.subs.add(this.route.url.subscribe(url => {
        if (url[0].path.includes('executive-tools')) {
          this.location.replaceState(`/executive-tools/executive-dashboard${tabsUrl}`)
        }
      }))

      this.subs.add(this.store.select(selectAccount).subscribe(account => {
        const userDashboards =  window.btoa(encodeURIComponent(JSON.stringify(this.user.dashboard_tabs)))
        const url = `//${window.sobrio_host_name}/${account.allegro_slug}/executive_tools${tabsUrl}?dashboards=${userDashboards}`
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
      }))
    }
  }

  onMessageListener = (event) => {
    const { data, origin } = event

    if (origin.includes(window.sobrio_host_name)) {
      const parsedData = JSON.parse(data)

      if (parsedData.openGoalsSettings) {
        this.router.navigate(['/executive-tools/edit-goals'])
      }

      if (parsedData.execDashTabId) {
        this.location.replaceState(`/executive-tools/executive-dashboard/tabs/${parsedData.execDashTabId}`)
      }
      if (parsedData.execDashTabId === null) {
        this.location.replaceState(`/executive-tools/executive-dashboard`)
      }

      if (parsedData.dashboard_id) {
        this.store.dispatch(
          new DashboardSendChartToCustom(this.createCustomDashboardPayload(parsedData)))
      }
    }
  }

  createCustomDashboardPayload(data): DashboardSendChartToCustomPayload {
    return {
      dashboardTab: {
        id: data.dashboard_id,
        name: this.user.dashboard_tabs.find(tab => tab.id === Number(data.dashboard_id)).name
      },
      dataSource: 'jobs-summary',
      dataSourceParameters: {},
      chartType: 'table',
      chartName: 'Executive Dashboard Jobs Table',
      filters: null,
      isTitle: false,
      extra_params: {
        job_ids: data.job_ids,
        ordered_columns: data.ordered_columns,
        jobs_table_cand_types_for_stage_phase: data.jobs_table_cand_types_for_stage_phase,
        tab_id: data.tab_id // Allegro's tab_id
      }
    }
  }

  setActiveTab() {
    switch (true) {
      case this.router.url.includes('funnel-segmentation'):
        this.active = 'funnel-segmentation'
        break;
      case this.router.url.includes('capacity-planning'):
        this.active = 'capacity-planning'
        break;
      default:
        this.active = 'executive-dashboard'
        break;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
    if (this.user.enable_allegro_exec_dash) {
      this.renderer.removeClass(document.documentElement, 'tw-h-full')
      this.renderer.removeClass(document.documentElement, 'hide-scrollbar')
      this.renderer.removeClass(document.body, 'tw-h-full')
      this.renderer.removeClass(document.body, 'hide-scrollbar')

      if (this.appRoot && this.ngContainer) {
        this.renderer.removeClass(this.appRoot, 'tw-h-full')
        this.renderer.removeClass(this.ngContainer, 'tw-h-full')
      }
    }
    window.removeEventListener('message', this.onMessageListener)
  }
}
